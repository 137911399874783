import { Cooperative, Parcel, RegisteredCooperative } from 'src/app/shared';

import { Statuses } from './statuses.model';
import { RasterStat } from './raster-stat.model';
import { Entity, LinkResource } from 'src/app/core/hal';

export class ParcelRecommendation extends LinkResource implements Entity {
  id: string;
  active = true;
  rasterStat = new RasterStat();
  statuses = new Statuses();
  parcel: Parcel;
  recommendationId: string;
  cooperative?: Cooperative;
  registeredCooperative?: RegisteredCooperative;
  recommendationFile?: EmbeddedFile;

  getRelativeApiUrl = () => '/fs-parcel/api/parcelRecommendations';

  constructor(data?: any) {
    super(data);
    if (data) {
      Object.assign(this, data);
      if (data.registeredCooperative) {
        this.registeredCooperative = new RegisteredCooperative(data.registeredCooperative);
      }
    }
  }
}

class EmbeddedFile {
  name: string;
  createdOn: Date;
  size: number;
  contentType: string;
  fileContent: EmbeddedFileContent;

  constructor(recommendationFileData?: any) {
    if (recommendationFileData) {
      Object.assign(this, recommendationFileData);
      this.fileContent = new EmbeddedFileContent(recommendationFileData.fileContent);
    }
  }
}

class EmbeddedFileContent {
  id: string;
  contentBinary: any;

  constructor(fileContentData?: any) {
    if (fileContentData) {
      Object.assign(this, fileContentData);
    }
  }
}
