import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecommendationValidationComponent } from './recommendation-validation.component';
import { RecommendationValidationFilterComponent } from './recommendation-validation-filter/recommendation-validation-filter.component';
// tslint:disable-next-line:max-line-length
import { RecommendationValidationDatagridComponent } from './recommendation-validation-datagrid/recommendation-validation-datagrid.component';
// tslint:disable-next-line:max-line-length
import { RecommendationValidationDistributionChartComponent } from './recommendation-validation-distribution-chart/recommendation-validation-distribution-chart.component';
// tslint:disable-next-line:max-line-length
import { RecommendationValidationEfficiencyChartComponent } from './recommendation-validation-efficiency-chart/recommendation-validation-efficiency-chart.component';
import { RecommendationSelectComponent } from './recommendation-validation-filter/recommendation-select/recommendation-select.component';
import { SharedModule } from 'src/app/shared';
// tslint:disable-next-line:max-line-length
import { RecommendationValidationStatusMenuComponent } from './recommendation-validation-datagrid/recommendation-validation-status-menu/recommendation-validation-status-menu.component';
import { DxMenuModule, DxTextBoxModule } from 'devextreme-angular';

@NgModule({
  declarations: [
    RecommendationValidationComponent,
    RecommendationValidationFilterComponent,
    RecommendationValidationDatagridComponent,
    RecommendationValidationDistributionChartComponent,
    RecommendationValidationEfficiencyChartComponent,
    RecommendationSelectComponent,
    RecommendationValidationStatusMenuComponent,
  ],
  imports: [CommonModule, SharedModule, DxMenuModule, DxTextBoxModule],
})
export class RecommendationValidationModule {}
