import { Component, OnInit, ViewChild } from '@angular/core';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { tap } from 'rxjs/operators';
import { FarmerService, Farmer, Campaign, Cooperative } from 'src/app/shared';
import { mapErrorToMsg, ResponseEntities } from 'src/app/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { HttpParams } from '@angular/common/http';
import { DxDatasourceCreatorService } from 'src/app/core/dx';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-farmers-list',
  templateUrl: './farmers-list.component.html',
  styleUrls: ['./farmers-list.component.scss'],
})
export class FarmersListComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  icons = faIcons;
  dataSource: DataSource;
  totalNbFarmers: number;
  campaignFilter: string;
  coopFilter: string;

  constructor(
    private farmerService: FarmerService,
    private datasourceCreator: DxDatasourceCreatorService
  ) {
    this.calculateCooperative.bind(this);
    this.calculateCodeInCoop.bind(this);
  }

  ngOnInit() {
    this.initDataSource();
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: params =>
        this.loadFarmerList(params.httpParams, this.campaignFilter, this.coopFilter).pipe(
          tap(response => (this.totalNbFarmers = response.page.totalElements)),
          mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !')
        ),
      getLoadParams: () => ({ httpParams: new HttpParams().set('projection', 'list') }),
    });
  }

  loadFarmerList(
    httpParams: HttpParams,
    campaign: string,
    coop: string
  ): Observable<ResponseEntities<Farmer>> {
    let params = httpParams;
    if (campaign) {
      params = params.set('registeredFarmers.campaign', campaign);
    }
    if (coop) {
      params = params.set('registeredFarmers.registeredCooperative.cooperative', coop);
    }

    return this.farmerService.getByHttpParams(params);
  }

  calculateFullName(farmerRow: Farmer) {
    return farmerRow.getFullName();
  }

  calculateCooperative = (farmerRow: Farmer) => {
    if (this.campaignFilter) {
      const registeredFarmer = this.getRegisteredFarmerFromCampaign(farmerRow, this.campaignFilter);
      return registeredFarmer ? registeredFarmer.registeredCooperative.cooperative.code : '';
    }
    return farmerRow.registeredFarmers
      ? farmerRow.registeredFarmers.map(rf => rf.registeredCooperative.cooperative.code).join(',')
      : '';
  };

  calculateCodeInCoop = (farmerRow: Farmer) => {
    if (this.campaignFilter) {
      const registeredFarmer = this.getRegisteredFarmerFromCampaign(farmerRow, this.campaignFilter);
      return registeredFarmer ? registeredFarmer.codeInCoop : '';
    }
    return farmerRow.registeredFarmers
      ? farmerRow.registeredFarmers.map(rf => rf.codeInCoop).join(',')
      : '';
  };

  getRegisteredFarmerFromCampaign(farmerRow: Farmer, campaign: string) {
    return farmerRow.registeredFarmers && campaign
      ? farmerRow.registeredFarmers.find(rf => rf.campaign.id === campaign)
      : null;
  }

  exportDataGrid() {
    this.dataGrid.instance.exportToExcel(false);
  }

  applyCampaignFilter(filter: Campaign) {
    this.campaignFilter = filter ? filter.id : null;
    this.dataGrid.instance.refresh();
  }

  applyCoopFilter(filter: Cooperative) {
    this.coopFilter = filter ? filter.id : null;
    this.dataGrid.instance.refresh();
  }
}
