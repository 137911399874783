import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/internal/operators';
import { EntityProviderService, ResponseEntities, tapLogError } from 'src/app/core';
import { RegisteredTechnician } from './registered-technician.model';

@Injectable()
export class RegisteredTechnicianService {
  getByHttpParams = this.entityProviderService.httpGetter(RegisteredTechnician);

  constructor(
    private httpClient: HttpClient,
    private entityProviderService: EntityProviderService
  ) {}

  getRegisteredTechniciansByTechnicianId(
    technicianId: string
  ): Observable<ResponseEntities<RegisteredTechnician>> {
    return this.getByHttpParams(new HttpParams().set('id', technicianId)).pipe(
      tapLogError('getRegisteredTechnicians'),
      shareReplay(1)
    );
  }
}
