import { Entity, LinkResource } from 'src/app/core/hal';
import { RegisteredTechnician } from 'src/app/shared/registered-technician/registered-technician.model';

export class Technician extends LinkResource implements Entity {
  adress: string;
  city: string;
  email: string;
  firstName: string;
  id: string;
  idpIdentifier: string;
  lastModifiedDate: Date;
  lastName: string;
  login: string;
  phone1: string;
  phone2: string;
  profile: string;
  zipCode: string;
  active: boolean;
  code: string;
  codeInCoop: string;
  registeredTechnicians?: RegisteredTechnician[];

  getRelativeApiUrl = () => '/fs-core/api/technicians';

  constructor(technicianDetailsData: any) {
    super(technicianDetailsData);
    if (technicianDetailsData) {
      if (technicianDetailsData) {
        Object.assign(this, technicianDetailsData);
        if (technicianDetailsData.registeredTechnicians) {
          this.registeredTechnicians = technicianDetailsData.registeredTechnicians.map(
            (rt: any) => new RegisteredTechnician(rt)
          );
        }
      }
    }
  }

  getFullName() {
    return [this.firstName, this.lastName].join(' ').trim();
  }
}
