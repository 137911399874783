import { LinkResource, Entity } from 'src/app/core/hal';

export class FwRemoteRequest extends LinkResource implements Entity {
  id: string;
  message: string;
  sendDate: Date;
  responseDate: Date;
  zipFile;
  // fwRemoteParcelRequests?: FwRemoteParcelRequest[];
  failed: boolean;

  constructor(data?: any) {
    super(data);
    if (data) {
      Object.assign(this, data);
      if (data.fwRemoteParcelRequests) {
        // this.fwRemoteParcelRequests = data.fwRemoteParcelRequests.map(
        //   frpr => new FwRemoteParcelRequest(frpr)
        // );
      }
    }
  }
  getRelativeApiUrl = () => '/fs-connector-fertiweb/api/fwRemoteRequests';
}
