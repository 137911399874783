import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CooperativeSelectComponent } from './cooperative-select/cooperative-select.component';
import { DxSelectBoxModule, DxTextBoxModule } from 'devextreme-angular';
import { FormsModule } from '@angular/forms';
import { CampaignSelectComponent } from './campaign-select/campaign-select.component';
import { InputDebounceComponent } from './input-debounce.component';
import { NdoseComputationMethodSelectComponent } from './ndose-computation-method-select.component';
import { CropSelectComponent } from './crop-select.component';
import { KindSelectComponent } from './kind-select/kind-select.component';

@NgModule({
  declarations: [
    CooperativeSelectComponent,
    CampaignSelectComponent,
    InputDebounceComponent,
    NdoseComputationMethodSelectComponent,
    CropSelectComponent,
    KindSelectComponent,
  ],
  imports: [CommonModule, FormsModule, DxSelectBoxModule, DxTextBoxModule],
  exports: [
    CooperativeSelectComponent,
    CampaignSelectComponent,
    InputDebounceComponent,
    NdoseComputationMethodSelectComponent,
    CropSelectComponent,
    KindSelectComponent,
  ],
})
export class FormComponentsModule {}
