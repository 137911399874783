import { User } from 'src/app/shared/user/user.model';
import { OnInit, ViewChild, Injectable } from '@angular/core';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { UserService } from 'src/app/shared';
import { Cooperative, CooperativeService } from 'src/app/shared';
import { Observable } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { DxDatasourceCreatorService } from 'src/app/core/dx';
import DataSource from 'devextreme/data/data_source';
import { tap } from 'rxjs/operators';
import { mapErrorToMsg, ResponseEntities } from 'src/app/core';
import { RestResourceService } from 'src/app/core';

@Injectable({ providedIn: 'root' })
export abstract class UsersListComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  icons = faIcons;
  cooperatives: Cooperative[];
  totalNbUsers: number;
  selectedCooperatives: String[];
  calculateCooperativeNamesBinded = this.calculateCooperativeNames.bind(this);
  projection = 'list';
  dataSource: DataSource;
  displayCoop = false;
  displayEmail = false;
  displayPhone1 = false;
  displayPhone2 = false;

  constructor(
    protected cooperativeService: CooperativeService,
    protected restResourceService: RestResourceService,
    protected userService: UserService,
    protected datasourceCreator: DxDatasourceCreatorService
  ) {}

  ngOnInit() {
    this.initDataSource();
    this.cooperativeService
      .getAllCooperatives(this.projection)
      .subscribe(coops => (this.cooperatives = coops));
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: searchParams =>
        this.loadUser(searchParams, this.getEndpointFilter()).pipe(
          tap(response => (this.totalNbUsers = response.page.totalElements)),
          mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !')
        ),
      getLoadParams: () => ({ projection: this.projection }),
      update: this.updateRow.bind(this),
      insert: this.addRow.bind(this),
      delete: this.deleteRow.bind(this),
    });
  }

  loadUser(searchParams, endpoint: string): Observable<ResponseEntities<User>> {
    return this.userService.getPaginedUsers(this.getType(), searchParams, endpoint);
  }

  calculateFullName(user): String {
    return user.lastName + ' ' + user.firstName;
  }

  calculateCooperativeNames(user): String {
    return user.cooperatives
      ? user.cooperatives.map(coop => this.calculateCooperativeNameWithCode(coop)).join(', ')
      : '';
  }

  calculateCooperativeNameWithCode(cooperative): String {
    return cooperative.name + ' (' + cooperative.code + ')';
  }

  exportDataGrid() {
    this.dataGrid.instance.exportToExcel(false);
  }

  onTagBoxInitialized(data) {
    this.selectedCooperatives = data.data.cooperatives
      ? data.data.cooperatives.map(coop => coop.id)
      : [];
  }

  getSelectedCooperativesUrl(): string[] {
    return this.cooperatives
      .filter(coop => this.selectedCooperatives.includes(coop.id))
      .map(coop => this.restResourceService.getUrl(coop));
  }

  updateRow(key, value): Promise<any> {
    value.cooperatives = this.getSelectedCooperativesUrl();
    return this.userService
      .updateUser(key.id, value, this.getType(), this.getEndpoint())
      .pipe(mapErrorToMsg('Il y a eu une erreur pendant la modification du coordinateur !'))
      .toPromise();
  }

  addRow(value) {
    value.cooperatives = this.getSelectedCooperativesUrl();
    return this.userService
      .addUser(value, this.getType(), this.getEndpoint())
      .pipe(mapErrorToMsg("Il y a eu une erreur pendant l'ajout du coordinateur !"))
      .toPromise();
  }

  deleteRow(value) {
    return this.userService
      .deleteUser(value.id, this.getEndpoint())
      .pipe(mapErrorToMsg('Il y a eu une erreur pendant la suppression du coordinateur !'))
      .toPromise();
  }

  abstract getEndpoint(): string;

  abstract getEndpointFilter(): string;

  abstract getType(): any;
}
