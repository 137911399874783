import { HttpParams } from '@angular/common/http';
import { LoadOptionsModel } from 'src/app/core/dx';
import * as _ from 'lodash';

export class SpringSearchParams {
  projection: string;
  size: number;
  page: number;
  skip: number;
  sort: string;
  group: string;
  filter: string;
  requireTotalCount: boolean;
  requireGroupCount: boolean;

  static fromLoadOptions(loadOptions: LoadOptionsModel): SpringSearchParams {
    const searchParam = new SpringSearchParams();
    searchParam.page = loadOptions.skip / loadOptions.take;
    searchParam.size = loadOptions.take;
    searchParam.skip = loadOptions.skip;
    if (loadOptions.sort && loadOptions.sort['0'] && loadOptions.sort['0'].selector) {
      if (_.isArray(loadOptions.sort) && loadOptions.sort.length > 1) {
        searchParam.sort = JSON.stringify(loadOptions.sort);
      } else {
        const selector = loadOptions.sort['0'];
        searchParam.sort = this.getSimpleSort(selector);
      }
    }
    if (loadOptions.group && loadOptions.group['0'] && loadOptions.group['0'].selector) {
      const selector = loadOptions.group['0'];
      searchParam.group = this.getSimpleSort(selector);
    }
    if (loadOptions.requireTotalCount !== undefined) {
      searchParam.requireTotalCount = loadOptions.requireTotalCount;
    }
    if (loadOptions.requireGroupCount !== undefined) {
      searchParam.requireGroupCount = loadOptions.requireGroupCount;
    }
    if (loadOptions.filter !== undefined) {
      searchParam.filter = JSON.stringify(loadOptions.filter);
    }
    return searchParam;
  }

  static getSimpleSort(selector) {
    return selector.selector + ',' + (selector.desc ? 'desc' : 'asc');
  }

  toHttpParams(httpParams?: HttpParams): HttpParams {
    if (!httpParams) {
      httpParams = new HttpParams();
    }

    if (this.size !== undefined) {
      httpParams = httpParams.set('size', String(this.size));
    }

    if (this.skip !== undefined) {
      httpParams = httpParams.set('skip', String(this.skip));
    }

    if (this.page !== undefined) {
      httpParams = httpParams.set('page', String(this.page));
    }

    if (this.sort !== undefined) {
      httpParams = httpParams.set('sort', String(this.sort));
    }

    if (this.group !== undefined) {
      httpParams = httpParams.set('group', String(this.group));
    }

    if (this.requireTotalCount !== undefined) {
      httpParams = httpParams.set('requireTotalCount', String(this.requireTotalCount));
    }

    if (this.requireGroupCount !== undefined) {
      httpParams = httpParams.set('requireGroupCount', String(this.requireGroupCount));
    }

    if (this.filter !== undefined) {
      httpParams = httpParams.set('filter', String(this.filter));
    }

    return httpParams;
  }
}
