import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SubscribeParcels } from './subscribe-parcels.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { shareReplay, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SubscribeParcelsService {
  relativeUrl = '/fs-connector-a3i/api/subscribeParcels';
  fsConnectorA3iApi = environment.api + this.relativeUrl;

  constructor(private httpClient: HttpClient) {}

  searchByHttpParamsFromA3i(registeredParcelId: string): Observable<SubscribeParcels> {
    const url = this.fsConnectorA3iApi + '/search/findFirstByRegisteredParcelRefId';
    const params = { registeredParcelRefId: registeredParcelId };
    return this.httpClient
      .get<SubscribeParcels>(url, { params: new HttpParams({ fromObject: params }) })
      .pipe(
        map(a => new SubscribeParcels(a)),
        shareReplay(1)
      );
  }
}
