import { LinkResource } from 'src/app/core';

export class SubscribeParcels extends LinkResource {
  id: string;
  parcela3iId: string;
  project: EmbeddedRegisteredProject;
  registeredParcelRefId: string;

  getRelativeApiUrl = () => '/fs-connector-a3i/api/subscribeParcels';

  constructor(subscribeParcelsData?: any) {
    super(subscribeParcelsData);
    if (subscribeParcelsData) {
      Object.assign(this, subscribeParcelsData);
    }
  }
}

class EmbeddedRegisteredProject extends LinkResource {
  a3iId: string;
  id: string;
  name: string;
  constructor(registeredProjectData?: any) {
    super(registeredProjectData);
    if (registeredProjectData) {
      Object.assign(this, registeredProjectData);
    }
  }
}
