import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { SubscribedParcels } from './subscribed-parcels.model';

@Injectable({
  providedIn: 'root',
})
export class SubscribedParcelsService {
  relativeIrrelisUrl = '/fs-connector-irrelis/api/subscribedParcels';
  fsConnectorIrrelisApi = environment.api + this.relativeIrrelisUrl;

  relativePrevilisUrl = '/fs-connector-previlis/api/subscribedParcels';
  fsConnectorPrevilisApi = environment.api + this.relativePrevilisUrl;

  constructor(private httpClient: HttpClient) {}

  searchByHttpParamsFromIrrelis(parcelId: string): Observable<SubscribedParcels> {
    const url = this.fsConnectorIrrelisApi + '/search/findFirstByParcelRefId';
    const params = { parcelRefId: parcelId };
    return this.httpClient
      .get<SubscribedParcels>(url, {
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(
        map(a => new SubscribedParcels(a)),
        shareReplay(1)
      );
  }

  searchByHttpParamsFromPrevilis(): Observable<SubscribedParcels> {
    const url = this.fsConnectorPrevilisApi + '/search/findFirstByParcelRefId';
    const params = { parcelRefId: 'fakeParcelIdPrevilis' };
    return this.httpClient
      .get<SubscribedParcels>(url, {
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(
        map(a => new SubscribedParcels(a)),
        shareReplay(1)
      );
  }
}
