import { Cooperative, Campaign } from 'src/app/shared';
import { Recommendation } from './recommendation.model';
import { RecommendationValidationStatusType } from './recommendation-validation-status-type.model';
import { HttpParams } from '@angular/common/http';

export class RecommendationValidationFilter {
  cooperative: Cooperative;
  department: string;
  campaign: Campaign;
  recommendation: Recommendation;

  computationStatus: RecommendationValidationStatusType;
  productionStatus: RecommendationValidationStatusType;
  expertValidationStatus: RecommendationValidationStatusType;
  customerSupportValidationStatus: RecommendationValidationStatusType;
  instituteValidationStatus: RecommendationValidationStatusType;
  deliveryStatus: RecommendationValidationStatusType;

  registeredCooperativeRefIds: string[];

  constructor(data?: RecommendationValidationFilter) {
    if (data) {
      Object.assign(this, data);
    }
  }

  addToParams(params: HttpParams) {
    if (this.department) {
      params = params.set('codeDepartment', this.department);
    }
    if (this.computationStatus) {
      params = params.set('computationStatus', this.computationStatus);
    }
    if (this.productionStatus) {
      params = params.set('productionStatus', this.productionStatus);
    }
    if (this.expertValidationStatus) {
      params = params.set('expertValidationStatus', this.expertValidationStatus);
    }
    if (this.customerSupportValidationStatus) {
      params = params.set('customerSupportValidationStatus', this.customerSupportValidationStatus);
    }
    if (this.instituteValidationStatus) {
      params = params.set('instituteValidationStatus', this.instituteValidationStatus);
    }
    if (this.deliveryStatus) {
      params = params.set('deliveryStatus', this.deliveryStatus);
    }
    if (this.registeredCooperativeRefIds) {
      this.registeredCooperativeRefIds.forEach(
        id => (params = params.append('registeredCooperativeRefIds', id))
      );
    }

    return params;
  }
}
