import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ResponseEntities,
  toResponseEntities,
  tapLogError,
  mapErrorToMsg,
  HttpMappingService,
  EntityProviderService,
} from 'src/app/core';
import { LatestErrors } from './latest-errors.model';
import { shareReplay, map } from 'rxjs/operators';
import { RegisteredParcel } from 'src/app/shared/registered-parcel/registered-parcel.model';
import { Parcel } from 'src/app/shared/parcel/parcel.model';
import { GroupCount } from './group-count.model';
import { ParcelError } from 'src/app/shared/fs-fertiweb/parcel-error/parcel-error.model';
import { FileDownloaderService } from 'src/app/shared/files/file-downloader.service';

@Injectable()
export class LatestErrorsService {
  fsconnectorFertiwebErrorsApi = environment.api + '/fs-connector-fertiweb/api/errors/';
  fsconnectorFertiwebApi = environment.api + '/fs-connector-fertiweb/api';
  latestsOfEachParcelApi = this.fsconnectorFertiwebApi + '/parcelErrors/latestsOfEachParcel';
  latestsOfEachParcelGroupApi =
    this.fsconnectorFertiwebApi + '/parcelErrors/latestsOfEachParcelGroup';

  constructor(
    private httpClient: HttpClient,
    private httpMappingService: HttpMappingService,
    private entityProviderService: EntityProviderService,
    private fileDownloaderService: FileDownloaderService
  ) {}

  getByHttpParams(
    params: HttpParams,
    endpoint: String
  ): Observable<ResponseEntities<LatestErrors>> {
    return this.httpClient
      .get<ResponseEntities<LatestErrors>>(this.fsconnectorFertiwebErrorsApi + endpoint, { params })
      .pipe(
        toResponseEntities(LatestErrors),
        shareReplay(1)
      );
  }

  getLatestsErrorsOfEachParcelByHttpParams(params: HttpParams): Observable<ResponseEntities<any>> {
    let response$;
    if (params.get('group')) {
      const totalElements$ = this.httpClient
        .get(this.latestsOfEachParcelApi, { params: params.set('size', '1') })
        .pipe(
          shareReplay(1),
          mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !'),
          toResponseEntities(ParcelError)
        );
      const groups$ = this.httpClient.get(this.latestsOfEachParcelGroupApi, { params }).pipe(
        shareReplay(1),
        mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !'),
        toResponseEntities(GroupCount)
      );
      response$ = forkJoin(groups$, totalElements$).pipe(
        map(([groups, totResp]) => {
          groups.page.totalElements = totResp.page.totalElements;
          return groups;
        })
      );
    } else {
      response$ = this.httpClient.get(this.latestsOfEachParcelApi, { params }).pipe(
        toResponseEntities(ParcelError),
        shareReplay(1),
        this.httpMappingService.mapEntities({
          pathToIdInInput: 'fwRemoteParcelRequest.registeredParcelId',
          pathToDataInOutput: 'registeredParcel',
          targetType: RegisteredParcel,
          projection: 'parcelRef',
        }),
        this.httpMappingService.mapEntities({
          pathToIdInInput: 'registeredParcel.parcelRefId',
          pathToDataInOutput: 'parcel',
          targetType: Parcel,
          projection: 'parcelIdCode',
        }),
        mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !')
      );
    }
    return response$;
  }

  getLatestErrorParcelByHttpParams(
    params: HttpParams,
    registeredParcelId: String
  ): Observable<ResponseEntities<LatestErrors>> {
    return this.getByHttpParams(params, registeredParcelId).pipe(
      map(a => new ResponseEntities<LatestErrors>(LatestErrors, a)),
      map(a => a.getAllEmbedded()),
      tapLogError('getLatestErrorParcelByHttpParams'),
      shareReplay(1)
    );
  }

  downloadZip(data: ParcelError) {
    const fileName =
      data.parcel.code +
      '_' +
      data.fwRemoteParcelRequest.fwRemoteRequest.sendDate.toString().replace(/:/g, '-') +
      '.zip';
    this.fileDownloaderService.downloadFileUrl(
      `${this.fsconnectorFertiwebApi}/files/${
        data.fwRemoteParcelRequest.fwRemoteRequest.zipFile.id
      }/download`,
      'application/zip',
      fileName
    );
  }
}
