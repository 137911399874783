export class GroupCount {
  key: string;
  count: number;

  items = null;
  summary = [];

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
      this.summary = [data.count];
    }
  }
}
