import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { mergeMap, map, shareReplay } from 'rxjs/operators';
import { RegisteredTechnician, RegisteredFarmer } from 'src/app/shared';
import { Cooperative } from './cooperative.model';
import { ResponseEntities, toResponseEntities } from 'src/app/core/hal';
import { tapLogError } from 'src/app/core';
import { FileDownloaderService } from 'src/app/shared/files/file-downloader.service';

@Injectable()
export class CooperativeService {
  fsUserCooperativesApi = environment.api + '/fs-core/api/cooperatives';

  constructor(private httpClient: HttpClient, private fileDownloader: FileDownloaderService) {}

  getCooperativeById(id: string): Observable<Cooperative> {
    const cooperativeUrl = this.fsUserCooperativesApi + '/' + id;
    return this.httpClient.get<Observable<Cooperative>>(cooperativeUrl).pipe(
      map(a => new Cooperative(a)),
      tapLogError('getCooperativeById'),
      shareReplay(1)
    );
  }

  getCooperativeFromRegisteredTechnicians(
    registeredTechnician$: Observable<ResponseEntities<RegisteredTechnician>>
  ): Observable<Cooperative> {
    return registeredTechnician$.pipe(
      // FIXME, qd il y aura le filtre par campane, on aura qu'un seul registeredFarmer
      map(registeredFarmers => registeredFarmers.getAllEmbedded()[0]),
      map(a => a.registeredCooperative.cooperative.id),
      mergeMap(a => this.getCooperativeById(a)),
      shareReplay(1)
    );
  }

  getCooperativeFromRegisteredFarmers(
    registeredFarmers$: Observable<ResponseEntities<RegisteredFarmer>>
  ): Observable<Cooperative> {
    return registeredFarmers$.pipe(
      // FIXME, qd il y aura le filtre par campane, on aura qu'un seul registeredFarmer
      map(registeredFarmers => registeredFarmers.getAllEmbedded()[0]),
      map(a => a.registeredCooperative.cooperative.id),
      mergeMap(a => this.getCooperativeById(a)),
      shareReplay(1)
    );
  }

  getByHttpParams(params: HttpParams): Observable<ResponseEntities<Cooperative>> {
    return this.httpClient
      .get<ResponseEntities<Cooperative>>(this.fsUserCooperativesApi, { params })
      .pipe(
        toResponseEntities(Cooperative),
        shareReplay(1)
      );
  }

  updateCooperative(coopId: number, patchData): Observable<Cooperative> {
    const cooperativeUrl = this.fsUserCooperativesApi + '/' + coopId;
    return this.httpClient.patch<Cooperative>(cooperativeUrl, patchData).pipe(
      map(a => new Cooperative(a)),
      tapLogError('updateCooperative'),
      shareReplay(1)
    );
  }

  addCooperative(cooperative: Cooperative): Observable<Cooperative> {
    return this.httpClient.post<Cooperative>(this.fsUserCooperativesApi, cooperative).pipe(
      map(a => new Cooperative(a)),
      tapLogError('addCooperative'),
      shareReplay(1)
    );
  }

  getAllCooperatives(projection: string): Observable<Cooperative[]> {
    const params = { projection, sort: 'name,asc' };
    return this.httpClient.get<Cooperative[]>(this.fsUserCooperativesApi, { params }).pipe(
      map(a => new ResponseEntities<Cooperative>(Cooperative, a)),
      map(a => a.getAllEmbedded()),
      tapLogError('getAllCooperatives'),
      shareReplay(1)
    );
  }

  uploadLogos(coopId: string, small, large) {
    const cooperativeUrl = this.fsUserCooperativesApi + `/${coopId}/logos`;
    const formData = new FormData();
    if (small) {
      formData.append('small', small);
    }
    if (large) {
      formData.append('large', large);
    }
    return this.httpClient.post<Cooperative>(cooperativeUrl, formData).pipe(
      map(a => new Cooperative(a)),
      tapLogError('updateCooperative'),
      shareReplay(1)
    );
  }

  getLargeLogo(coopId: string) {
    const largeLogoUrl = this.fsUserCooperativesApi + `/${coopId}/logoLarge`;
    return this.httpClient
      .get<Blob>(largeLogoUrl, { observe: 'response', responseType: 'blob' as 'json' })
      .pipe(
        map((response: HttpResponse<any>) => response.body),
        tapLogError('getLargeLogo'),
        shareReplay(1)
      );
  }

  exportCooperatives() {
    const exportUrl = this.fsUserCooperativesApi + '/export';
    this.fileDownloader.downloadFileFromGet('Liste des coopératives.csv', exportUrl);
  }
}
