import { Entity, LinkResource } from 'src/app/core/hal';
import { RegisteredFarmer } from 'src/app/shared/registered-farmer/registered-farmer.model';

export class Farmer extends LinkResource implements Entity {
  adress: string;
  city: string;
  email: string;
  firstName: string;
  id: string;
  idpIdentifier: string;
  lastModifiedDate: Date;
  lastName: string;
  login: string;
  phone1: string;
  phone2: string;
  profile: string;
  zipCode: string;
  active: boolean;
  code: string;
  codeInCoop: string;
  corporateName: string;
  localeLanguageTag: string;
  siret: string;
  parcelCount?: number;
  registeredFarmers?: RegisteredFarmer[];

  getRelativeApiUrl = () => '/fs-core/api/farmers';

  constructor(farmerDetailsData?: any) {
    super(farmerDetailsData);
    if (farmerDetailsData) {
      Object.assign(this, farmerDetailsData);
      if (farmerDetailsData.registerFarmers) {
        this.registeredFarmers = farmerDetailsData.registeredFarmers.map(
          (rf: any) => new RegisteredFarmer(rf)
        );
      }
    }
  }

  getFullName() {
    return [this.firstName, this.lastName].join(' ').trim();
  }
}
