import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SubscribeParcels } from 'src/app/shared/subscribe-parcels/subscribe-parcels.model';
import { SubscribedParcels } from 'src/app/shared/subscribed-parcels/subscribed-parcels.model';

@Component({
  selector: 'app-subscribe-services-popup',
  templateUrl: './subscribe-services-popup.component.html',
  styleUrls: ['./subscribe-services-popup.component.scss'],
})
export class SubscribeServicesPopupComponent implements OnInit {
  @Input() popupOpen: boolean;
  @Output() popupOpenChange = new EventEmitter<boolean>();
  @Output() registerToIrrelisService = new EventEmitter<void>();
  @Output() registerToA3iService = new EventEmitter<void>();
  @Output() registerToPrevilisService = new EventEmitter<void>();

  @Input() set mode(mode: string) {
    this._mode = mode;
    if (mode === 'irrelis') {
      this.displayedId = this.irrelisData ? this.irrelisData.id : null;
      this.displayedServiceName = 'Irrelis';
    } else if (mode === 'a3i') {
      this.displayedId = this.a3iData ? this.a3iData.id : null;
      this.displayedServiceName = 'A3I';
    } else if (mode === 'previlis') {
      this.displayedId = this.previlisData ? this.previlisData.id : null;
      this.displayedServiceName = 'Previlis';
    }
  }

  @Input() a3iData: SubscribeParcels;
  @Input() irrelisData: SubscribedParcels;
  @Input() previlisData: SubscribeParcels;

  private displayedServiceName: string;
  public displayedId: string;
  private _mode: string;

  constructor() {}

  ngOnInit() {}

  popupClosed() {
    this.popupOpenChange.emit(false);
  }

  registerOnService() {
    if (this._mode === 'irrelis') {
      this.registerToIrrelisService.emit();
    } else if (this._mode === 'a3i') {
      this.registerToA3iService.emit();
    } else if (this._mode === 'previlis') {
      this.registerToPrevilisService.emit();
    }
  }
}
