import { Entity, LinkResource } from 'src/app/core/hal';

export class Parcel extends LinkResource implements Entity {
  id: string;
  code: string;
  externalCode: string;
  pacBlockNumber: number;
  name: string;
  declaredSurface: number;
  measuredSurface: number;
  declaredDepartment: number;
  active: boolean;
  lastModifiedDate: Date;
  agroData: EmbeddedAgroData;
  aoi: EmbeddedAoi;
  frenchCommune: EmbeddedFrenchCommune;
  projectedDoseComputationDate?: Date;
  registeredCooperativeRefId?: string;

  getRelativeApiUrl = () => '/fs-parcel/api/parcels';

  constructor(parcelData: any) {
    super(parcelData);
    if (parcelData) {
      Object.assign(this, parcelData);
      this.agroData = new EmbeddedAgroData(parcelData.agroData);
      this.aoi = new EmbeddedAoi(parcelData.aoi);
      this.frenchCommune = new EmbeddedFrenchCommune(parcelData.frenchCommune);
    }
  }
}

class EmbeddedAoi {
  originCrsName: string;
  crsName: string;
  centroid: EmbeddedCentroid;
  originPolygon: EmbeddedOriginPolygon;
  polygon: EmbeddedPolygon;
  constructor(aoiData?: any) {
    if (aoiData) {
      Object.assign(this, aoiData);
      this.centroid = new EmbeddedCentroid(aoiData.centroid);
      this.originPolygon = new EmbeddedOriginPolygon(aoiData.originPolygon);
      this.polygon = new EmbeddedPolygon(aoiData.polygon);
    }
  }
}

class EmbeddedCentroid {
  type: string;
  coordinates: Coordinates[];
  constructor(centroidData?: any) {
    if (centroidData) {
      Object.assign(this, centroidData);
      if (centroidData.coordinates) {
        this.coordinates = centroidData.coordinates.map(coordinate => new Coordinates(coordinate));
      }
    }
  }
}

class Coordinates {
  constructor(coordinatesData?: any) {
    if (coordinatesData) {
    }
  }
}

class EmbeddedOriginPolygon {
  type: string;
  coordinates: Coordinates[];
  constructor(originPolygonData?: any) {
    if (originPolygonData) {
      Object.assign(this, originPolygonData);
      if (originPolygonData.coordinates) {
        this.coordinates = originPolygonData.coordinates.map(
          coordinate => new Coordinates(coordinate)
        );
      }
    }
  }
}

class EmbeddedPolygon {
  type: string;
  coordinates: Coordinates[];
  constructor(polygonData?: any) {
    if (polygonData) {
      Object.assign(this, polygonData);
      if (polygonData.coordinates) {
        this.coordinates = polygonData.coordinates.map(coordinate => new Coordinates(coordinate));
      }
    }
  }
}

export class EmbeddedAgroData extends LinkResource {
  medianYieldTarget: string;
  soilRefId: string;
  soilOrganicMatterRate: string;
  soilInputLabel: string;
  activities: EmbeddedActivities;
  crop: EmbeddedCrop;
  nitrogenStatus: EmbeddedNitrogenStatus;
  ndoseComputationMethodRefId: string;
  seedingActivity: EmbeddedSeedingActivity;

  constructor(agroData?: any) {
    super(agroData);
    if (agroData) {
      Object.assign(this, agroData);
      this.activities = new EmbeddedActivities(agroData.activities);
      this.crop = new EmbeddedCrop(agroData.crop);
      this.nitrogenStatus = new EmbeddedNitrogenStatus(agroData.nitrogenStatus);
    }
  }
}

export class EmbeddedActivities {
  anteprevious: EmbeddedAnteprevious;
  irrigation: EmbeddedIrrigation;
  catchCropActivity: EmbeddedcatchCropActivity;
  grasslandActivity: EmbeddedGrasslandActivity;
  organicWasteActivity: EmbeddedOrganicWasteActivity;
  historicActivities: EmbeddedHistoricActivities;
  previous: EmbeddedPrevious;
  seedingActivity: EmbeddedSeedingActivity;
  constructor(activitiesData?: any) {
    if (activitiesData) {
      Object.assign(this, activitiesData);
    }
  }
}

export class EmbeddedCrop extends LinkResource {
  linkedVarietyUsed: boolean;
  linkedVarietyName: string;
  varietyRefId: string;
  kindRefId: string;
  cropRefId: string;
  kindData: EmbeddedKindData;
  constructor(cropsData?: any) {
    super(cropsData);
    if (cropsData) {
      Object.assign(this, cropsData);
    }
  }
}

class EmbeddedKindData {
  code: string;
  label: string;
}

class EmbeddedNitrogenStatus {
  catchCropEffect: number;
  deficit: number;
  fertilizerMicrobialEffect: number;
  fertilizerVolatilizationEffect: number;
  floodingEffect: number;
  grasslandEffect: number;
  humusMineralizationEffect: number;
  irrigationEffect: number;
  maxDose: number;
  mesuredNCredit: number;
  actualMesuredNCredit: number;
  nabsBalanceBegining: number;
  ncreditBalanceBegining: number;
  ncreditBalanceBeginingCollectedDate: string;
  ncreditYield: number;
  organicWasteEffect: number;
  previousCropMinEffect: number;
  projectedDose: number;
  symbioticFixationEffect: number;
  totalNeed: number;
  workingCoefficient: number;
  currentYearOrganicWasteEffect: number;
  historicOrganicWasteEffect: number;
  projectedDoseComputationDate: Date;

  constructor(nitrogenStatusData?: any) {
    if (nitrogenStatusData) {
      Object.assign(this, nitrogenStatusData);
      if (nitrogenStatusData.projectedDoseComputationDate) {
        this.projectedDoseComputationDate = new Date(
          nitrogenStatusData.projectedDoseComputationDate
        );
      }
    }
  }
}

class EmbeddedIrrigation {
  irrigated: boolean;
  irrigation: string;
  irragationNitrateLevel: string;
  waterTurnDurationDays: string;

  constructor(irrigationData?: any) {
    if (irrigationData) {
      Object.assign(this, irrigationData);
    }
  }
}

class EmbeddedcatchCropActivity extends LinkResource {
  seedingDate: Date;
  emergenceDate: Date;
  destructionDate: Date;
  catchCropRefId: string;
  catchCropBiomassLevelRefId: string;
  intermediateCropTypeRefId: string;

  constructor(catchCropActivityData?: any) {
    super(catchCropActivityData);
    if (catchCropActivityData) {
      Object.assign(this, catchCropActivityData);
    }
  }
}

class EmbeddedGrasslandActivity {
  grasslandTypeRefId: string;
  grasslandUsageRefId: string;
  regularlyReversed: boolean;
  reverseDate: string;
  reversedGrasslandAgeRefId: string;

  constructor(grasslandActivityData?: any) {
    if (grasslandActivityData) {
      Object.assign(this, grasslandActivityData);
    }
  }
}

class EmbeddedOrganicWasteActivity {
  inputs: Inputs[] = [];
  periodicInputRefs: PeriodicInputRefs[] = [];

  constructor(organicWasteActivityData?: any) {
    if (organicWasteActivityData.inputs) {
      this.inputs = organicWasteActivityData.inputs.map(input => new Inputs(input));
    }
    if (organicWasteActivityData.periodicInputRefs) {
      this.periodicInputRefs = organicWasteActivityData.periodicInputRefs.map(
        periodicInputRef => new PeriodicInputRefs(periodicInputRef)
      );
    }
  }
}

class EmbeddedAnteprevious {
  irrigated: string;
  organicWasteInputs: OrganicWasteInput[] = [];
  previousCropRefId: string;
  previousCropWasteUsageRefId: string;
  totalNDose: string;
  yield: string;

  constructor(antepreviousData?: any) {
    Object.assign(this, antepreviousData);
    if (antepreviousData.organicWasteInputs) {
      this.organicWasteInputs = antepreviousData.organicWasteInputs.map(
        organicWasteInput => new OrganicWasteInput(organicWasteInput)
      );
    }
  }
}

class OrganicWasteInput {
  dose: number;
  inputDate: string;
  organicWasteInputModeRefId: string;
  organicWasteRefId: string;
  constructor(organicWasteInputsData?: any) {
    Object.assign(this, organicWasteInputsData);
  }
}

class EmbeddedHistoricActivities {
  historicCropWasteUsageRefId: string;
  inputs: Inputs[] = [];

  constructor(historicActivitiesData?: any) {
    Object.assign(this, historicActivitiesData);
    if (historicActivitiesData.inputs) {
      this.inputs = historicActivitiesData.inputs.map(input => new Inputs(input));
    }
  }
}

class EmbeddedPrevious {
  irrigated: boolean;
  organicWasteInputs: OrganicWasteInput[] = [];
  previousCropRefId: string;
  previousCropWasteUsageRefId: string;
  totalNDose: string;
  yield: string;

  constructor(previousData?: any) {
    Object.assign(this, previousData);
    if (previousData.organicWasteInputs) {
      this.organicWasteInputs = previousData.organicWasteInputs.map(
        organicWasteInput => new OrganicWasteInput(organicWasteInput)
      );
    }
  }
}

class EmbeddedSeedingActivity {
  seedingDate: Date;
  density: string;
  rawSpacing: string;
  actualSeedingDate: DataCue;
  constructor(seedingActivityData?: any) {
    Object.assign(this, seedingActivityData);
  }
}

class Inputs {
  dose: number;
  inputDate: string;
  organicWasteInputModeRefId: string;
  organicWasteRefId: string;

  constructor(inputsData?: any) {
    if (inputsData) {
      Object.assign(this, inputsData);
    }
  }
}

class PeriodicInputRefs {
  periodicInputRefId: string;

  constructor(periodicInputRefsData?: any) {
    if (periodicInputRefsData) {
      Object.assign(this, periodicInputRefsData);
    }
  }
}

class EmbeddedFrenchCommune {
  codeInsee: string;
  name: string;
  codeDepartment: string;

  constructor(frenchCommuneData?: any) {
    if (frenchCommuneData) {
      Object.assign(this, frenchCommuneData);
    }
  }
}
