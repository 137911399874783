import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Parcel, EmbeddedActivities } from './parcel.model';
import { ParcelCardDto } from './parcel-card-dto.model';
import { ParcelDistributionDto } from './parcel-distribution-dto.model';
import { RegisteredFarmer } from 'src/app/shared';
import { map, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ResponseEntities, tapLogError } from 'src/app/core';
import { toResponseEntities, EntityProviderService } from 'src/app/core/hal';
import * as _ from 'lodash';
import { PhenologicalStage } from 'src/app/shared/phenological-stage/phenological-stage.model';
import { StageEstimate } from 'src/app/shared/stage-estimate/stage-estimate.model';
import { FileDownloaderService } from 'src/app/shared/files/file-downloader.service';

@Injectable({
  providedIn: 'root',
})
export class ParcelService {
  fsParcelApi = environment.api + '/fs-parcel/api/parcels';
  fsAgroDatum = environment.api + '/fs-agro-datum/api';
  fsParcel = '/fs-parcel/api/parcels';

  getParcelByHttpParams = this.entityProviderService.httpGetter(Parcel);

  constructor(
    private httpClient: HttpClient,
    private fileDownloader: FileDownloaderService,
    private entityProviderService: EntityProviderService
  ) {}

  httpGet = this.entityProviderService.httpGetter(Parcel, this.fsParcel);

  getFilteredParcels(
    searchParams: {
      httpParams: HttpParams;
    },
    cooperative: string,
    campaign: string
  ): Observable<ResponseEntities<Parcel>> {
    let parcelUrl = this.fsParcelApi;
    let params = searchParams.httpParams;
    if (cooperative) {
      params = params.set('cooperative', cooperative);
    }
    if (campaign) {
      params = params.set('campaign', campaign);
    }
    if (cooperative || campaign) {
      parcelUrl += '/filterByCooperativeAndCampaign';
    }

    return this.httpClient.get<ResponseEntities<Parcel>>(parcelUrl, { params }).pipe(
      toResponseEntities(Parcel),
      shareReplay(1)
    );
  }

  getParcelById(id: string, projection: string): Observable<Parcel> {
    const parcelUrl = this.fsParcelApi + `/${id}`;
    const params = { projection };
    return this.httpClient.get<Observable<Parcel>>(parcelUrl, { params }).pipe(
      map(a => new Parcel(a)),
      tapLogError('getParcelById'),
      shareReplay(1)
    );
  }

  getParcelByIdsWithEmbedded(
    ids: string[],
    params: HttpParams
  ): Observable<ResponseEntities<Parcel>> {
    const parcelUrl = environment.api + '/fs-parcel/api/parcels';
    ids.forEach(id => {
      params = params.append('id', id);
    });
    return this.httpClient
      .get<ResponseEntities<Parcel>>(parcelUrl, {
        params: params,
      })
      .pipe(
        map(a => new ResponseEntities<Parcel>(Parcel, a)),
        tapLogError('getParcelByIds'),
        shareReplay(1)
      );
  }

  getParcelsFromRegisteredFarmerWithParams(
    registeredFarmers$: Observable<ResponseEntities<RegisteredFarmer>>,
    params: HttpParams
  ): Observable<ResponseEntities<Parcel>> {
    return registeredFarmers$.pipe(
      map(registeredFarmers =>
        registeredFarmers
          .getAllEmbedded()
          .map(registeredFarmer => registeredFarmer.parcels)
          .reduce((parcels, curr) => parcels.concat(curr), [])
          .map(parcels => parcels.parcelRefId)
      ),
      mergeMap(ids =>
        ids.length > 0
          ? this.getParcelByIdsWithEmbedded(ids, params)
          : of(new ResponseEntities<Parcel>(Parcel))
      ),
      shareReplay(1)
    );
  }

  // return ResponseEntities because we need totalCount
  search(searchParams: {
    httpParams: HttpParams;
    query: string;
  }): Observable<ResponseEntities<Parcel>> {
    return this.searchByHttpParams(searchParams.httpParams.set('value', searchParams.query));
  }

  searchByHttpParams(params: HttpParams): Observable<ResponseEntities<Parcel>> {
    const url =
      environment.api +
      '/fs-parcel/api/parcels/search/findByCodeIgnoreCaseContainingOrNameIgnoreCaseContaining';
    return this.httpClient.get<ResponseEntities<Parcel>>(url, { params }).pipe(
      toResponseEntities(Parcel),
      shareReplay(1)
    );
  }

  getGeojsonParcelById(id: string): Observable<any> {
    return this.getGeojsonParcelByIds([id]);
  }

  getGeojsonParcelByIds(ids: string[]): Observable<any> {
    const urlGeojson = environment.api + '/fs-parcel/api/parcels/toGeoJson';
    const params = { parcelIds: ids, epsg: '4326' };
    return this.httpClient
      .get<ResponseEntities<any>>(urlGeojson, {
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(
        tapLogError('getGeojsonParcelById'),
        shareReplay(1)
      );
  }

  getParcelAgroData(parcel$: Observable<Parcel>): Observable<ParcelCardDto> {
    let parcelActivities: EmbeddedActivities;
    const url = this.fsAgroDatum + '/datum/list/getLabelCodeList';
    const getBody = (parcel: Parcel) => {
      const activities = parcel.agroData.activities;
      const grassland = activities.grasslandActivity;
      const catchCrop = activities.catchCropActivity;
      const historic = activities.historicActivities;
      const organicWastes = activities.organicWasteActivity.inputs
        .concat(activities.previous.organicWasteInputs || [])
        .concat(activities.anteprevious.organicWasteInputs || [])
        .concat(activities.historicActivities.inputs || []);
      return {
        grasslandUsage: [grassland.grasslandUsageRefId],
        grasslandType: [grassland.grasslandTypeRefId],
        reversedGrasslandAge: [grassland.reversedGrasslandAgeRefId],
        catchCrop: [catchCrop.catchCropRefId],
        biomassLevel: [catchCrop.catchCropBiomassLevelRefId],
        historicCropWasteUsage: [historic.historicCropWasteUsageRefId],
        nDoseComputationMethod: [parcel.agroData.ndoseComputationMethodRefId],
        previousCrop: [activities.previous.previousCropRefId],
        previousCropWasteUsage: [
          activities.previous.previousCropWasteUsageRefId,
          activities.anteprevious.previousCropWasteUsageRefId,
        ],
        organicWaste: _.flatMap(organicWastes, ow => ow.organicWasteRefId),
        inputMode: _.flatMap(organicWastes, ow => ow.organicWasteInputModeRefId),
        intermediateCropType: [catchCrop.intermediateCropTypeRefId],
      };
    };

    return parcel$.pipe(
      tap(parcel => (parcelActivities = parcel.agroData.activities)),
      map(parcel => getBody(parcel)),
      mergeMap(body => this.httpClient.post<any>(url, body)),
      map(data => new ParcelCardDto(data, parcelActivities)),
      shareReplay(1)
    );
  }

  // TODO should be in StageEstimate service
  findPhenologicalStagesLastEstimateStageBy(
    parcelId: string,
    projection: string
  ): Observable<ResponseEntities<StageEstimate>> {
    const url =
      environment.api +
      '/fs-parcel/api/stageEstimates/search/phenologicalStagesLastEstimateStageBy';
    const params = { parcelId, projection };
    return this.httpClient
      .get<ResponseEntities<StageEstimate>>(url, {
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(
        toResponseEntities(StageEstimate),
        shareReplay(1)
      );
  }

  // TODO should be in PhenologicalStage service
  getByHttpParams(params: HttpParams): Observable<ResponseEntities<PhenologicalStage>> {
    const url = environment.api + '/fs-growth-stage/api/phenologicalStages';
    return this.httpClient.get<ResponseEntities<PhenologicalStage>>(url, { params }).pipe(
      toResponseEntities(PhenologicalStage),
      shareReplay(1)
    );
  }

  getNDoseParcelStatistics(
    searchParams: {
      httpParams: HttpParams;
    },
    subpath: string
  ): Observable<ResponseEntities<Parcel>> {
    const url = this.fsParcelApi + '/statistics/' + subpath;
    const params = searchParams.httpParams;
    return this.httpClient.get<ResponseEntities<Parcel>>(url, { params }).pipe(
      toResponseEntities(Parcel),
      shareReplay(1)
    );
  }

  getNDoseParcelDistribution(params: HttpParams): Observable<ParcelDistributionDto[]> {
    const url = this.fsParcelApi + '/statistics/distribution';
    return this.httpClient.get<ParcelDistributionDto[]>(url, { params }).pipe(
      map(data => data.map(distrib => new ParcelDistributionDto(distrib))),
      shareReplay(1)
    );
  }

  getParcelsbyCampaignCropComputationMethod(
    campaign: string,
    crop: string,
    computationMethod: string
  ): Observable<string[]> {
    const url = this.fsParcelApi + '/byCampaignCropComputationMethod';
    const params = { campaign, crop, computationMethod };
    return this.httpClient.get<string[]>(url, { params });
  }

  exportNDoseStatistics(params: HttpParams): Observable<any> {
    const exportUrl = this.fsParcelApi + '/statistics/export';
    return this.fileDownloader.downloadFileFromGet(
      'Statistiques dose totale.csv',
      exportUrl,
      params
    );
  }

  // Patch
  getParcelPatchData(id: string) {
    const parcelUrl = this.fsParcelApi + `/${id}`;
    return this.httpClient.patch<ResponseEntities<Parcel>>(parcelUrl, id);
  }

  generatePdf(parcelId: string, recommendationId: string) {
    return this.httpClient.post<any>(
      environment.api +
        '/fs-parcel/api/parcelRecommendations/productions/byParcelIdRecommendationId',
      {},
      {
        params: new HttpParams()
          .set('parcelId', parcelId)
          .set('recommendationId', recommendationId),
      }
    );
  }

  generateTotalNMissingReports(params: HttpParams) {
    const url = this.fsParcelApi + '/totaln/createMissingReports';
    return this.httpClient.post<any>(url, params);
  }
}
