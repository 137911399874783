import * as _ from 'lodash';
import { EmbeddedActivities } from './parcel.model';

export class ParcelCardDto {
  biomassLevel: string;
  catchCrop: string;
  grasslandAge: string;
  grasslandType: string;
  grasslandUsage: string;
  historicCropWasteUsage: string;
  nDoseComputationMethod: string;
  previousCrop: string;
  previousCropWasteUsage: string;
  antepreviousCropWasteUsage: string;
  intermediateCropType: string;

  organicWastes: OrganicWasteDto[];
  previousOrganicWastes: OrganicWasteDto[];
  antepreviousOrganicWastes: OrganicWasteDto[];
  historicOrganicWastes: OrganicWasteDto[];

  constructor(data: any, activities: EmbeddedActivities) {
    if (data) {
      this.historicCropWasteUsage = this.getLabel(_.find(data.historicCropWasteUsage));
      this.grasslandType = this.getLabel(_.find(data.grasslandType));
      this.grasslandUsage = this.getLabel(_.find(data.grasslandUsage));
      this.grasslandAge = this.getLabel(_.find(data.reversedGrasslandAge));
      this.catchCrop = this.getLabel(_.find(data.catchCrop));
      this.biomassLevel = this.getLabel(_.find(data.biomassLevel));
      this.nDoseComputationMethod = this.getLabel(_.find(data.nDoseComputationMethod));
      this.intermediateCropType = this.getLabel(_.find(data.intermediateCropType));
      this.previousCrop = this.getLabel(_.find(data.previousCrop));
      this.previousCropWasteUsage = this.getLabel(
        data.previousCropWasteUsage[activities.previous.previousCropWasteUsageRefId]
      );
      this.antepreviousCropWasteUsage = this.getLabel(
        data.previousCropWasteUsage[activities.anteprevious.previousCropWasteUsageRefId]
      );

      this.organicWastes = [];
      if (activities.organicWasteActivity.inputs) {
        activities.organicWasteActivity.inputs.forEach(input => {
          this.organicWastes.push(this.buildOrganicWaste(data, input));
        });
      }

      this.previousOrganicWastes = [];
      if (activities.previous.organicWasteInputs) {
        activities.previous.organicWasteInputs.forEach(input => {
          this.previousOrganicWastes.push(this.buildOrganicWaste(data, input));
        });
      }

      this.antepreviousOrganicWastes = [];
      if (activities.anteprevious.organicWasteInputs) {
        activities.anteprevious.organicWasteInputs.forEach(input => {
          this.antepreviousOrganicWastes.push(this.buildOrganicWaste(data, input));
        });
      }

      this.historicOrganicWastes = [];
      if (activities.historicActivities.inputs) {
        activities.historicActivities.inputs.forEach(input => {
          this.historicOrganicWastes.push(this.buildOrganicWaste(data, input));
        });
      }
    }
  }

  getLabel(data) {
    if (data) {
      return data.label;
    }
    return null;
  }

  buildOrganicWaste(data, input) {
    const organicWaste = new OrganicWasteDto(input);
    organicWaste.label = this.getLabel(data.organicWaste[input.organicWasteRefId]);
    organicWaste.inputMode = this.getLabel(data.inputMode[input.organicWasteInputModeRefId]);
    return organicWaste;
  }

  getOrganicWastes() {
    return this.organicWastes.concat(
      this.previousOrganicWastes,
      this.antepreviousOrganicWastes,
      this.historicOrganicWastes
    );
  }

  getOrganicWasteRefs() {
    return this.getOrganicWastes().map(ow => ow.refId);
  }
}

class OrganicWasteDto {
  label: string;
  inputDate: string;
  dose: number;
  inputMode: string;
  refId: string;
  liquid: boolean;

  constructor(inputData: any) {
    this.inputDate = inputData.inputDate;
    this.dose = inputData.dose;
    this.refId = inputData.organicWasteRefId;
  }
}
