import { Component, OnChanges, Input } from '@angular/core';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import {
  Parcel,
  ParcelService,
  Crop,
  CropService,
  Variety,
  VarietyService,
  Farmer,
  Technician,
  Campaign,
  Kind,
  KindService,
  SoilService,
  Soil,
  TimeService,
} from 'src/app/shared';
import { Observable } from 'rxjs';
import { HttpMappingService } from 'src/app/core/rxjs-operators/http-mapping/http-mapping.service';
import { SubscribedParcelsService } from 'src/app/shared/subscribed-parcels/subscribed-parcels.service';
import { SubscribeParcelsService } from 'src/app/shared/subscribe-parcels/subscribe-parcels.service';
import { SubscribedParcels } from 'src/app/shared/subscribed-parcels/subscribed-parcels.model';
import { SubscribeParcels } from 'src/app/shared/subscribe-parcels/subscribe-parcels.model';

@Component({
  selector: 'app-parcel-details',
  templateUrl: './parcel-details.component.html',
  styleUrls: ['./parcel-details.component.scss'],
})
export class ParcelDetailsComponent implements OnChanges {
  @Input() parcelId: string;

  icons = faIcons;
  subscribeServicePopupVisible = false;
  subscribeServicePopupMode: string;
  parcel$: Observable<Parcel>;
  crop$: Observable<Crop>;
  variety$: Observable<Variety>;
  kind$: Observable<Kind>;
  soil$: Observable<Soil>;
  irrelisSubscribedParcel$: Observable<SubscribedParcels>;
  a3iSubscribeParcel$: Observable<SubscribeParcels>;
  previlisSubscribedParcel$: Observable<SubscribedParcels>;

  constructor(
    private parcelService: ParcelService,
    private cropService: CropService,
    private varietyService: VarietyService,
    private httpMappingService: HttpMappingService,
    private kindService: KindService,
    private soilService: SoilService,
    private timeService: TimeService,
    private subscribedParcelsService: SubscribedParcelsService,
    private subscribeParcelsService: SubscribeParcelsService
  ) {}

  ngOnChanges() {
    if (this.parcelId) {
      this.loadParcelDetails();
    }
  }

  loadParcelDetails() {
    this.parcel$ = this.parcelService.getParcelById(this.parcelId, 'card-detail').pipe(
      this.httpMappingService.mapEntity({
        linkToResource: '_links.registeredParcels',
        pathToDataInOutput: 'registeredParcelsData',
      }),
      this.httpMappingService.mapEntity({
        linkToResource:
          'registeredParcelsData._embedded.registeredParcels[0]._links.registeredFarmer',
        pathToDataInOutput: 'registeredFarmerData',
      }),
      this.httpMappingService.mapEntityLinks([
        'registeredFarmerData._links.registeredTechnician',
        'registeredFarmerData._links.registeredCooperative',
      ]),
      this.httpMappingService.mapEntity({
        linkToResource: 'registeredFarmerData._links.campaign',
        pathToDataInOutput: 'campaignData',
        projection: 'dropdown',
        targetType: Campaign,
      }),
      this.httpMappingService.mapEntity({
        linkToResource: 'registeredFarmerData._links.farmer',
        pathToDataInOutput: 'farmerData',
        projection: 'name',
        targetType: Farmer,
      }),
      this.httpMappingService.mapEntity({
        linkToResource: 'registeredFarmerData._links.registeredTechnician._data._links.technician',
        pathToDataInOutput: 'technicianData',
        projection: 'name',
        targetType: Technician,
      }),
      this.httpMappingService.mapEntity({
        linkToResource:
          'registeredFarmerData._links.registeredCooperative._data._links.cooperative',
        pathToDataInOutput: 'cooperativeData',
        projection: 'dropdown',
      }),
      this.httpMappingService.mapEntity({
        linkToResource: '_links.frenchCommune',
        pathToDataInOutput: 'frenchCommuneData',
      })
    );

    this.crop$ = this.cropService.getCropFromParcels(this.parcel$);
    this.variety$ = this.varietyService.getVarietyFromParcels(this.parcel$);
    this.kind$ = this.kindService.getKindFromParcel(this.parcel$);
    this.soil$ = this.soilService.getSoilFromParcel(this.parcel$);

    this.irrelisSubscribedParcel$ = this.subscribedParcelsService.searchByHttpParamsFromIrrelis(
      this.parcelId
    );

    this.parcel$.subscribe(parcel => {
      this.a3iSubscribeParcel$ = this.subscribeParcelsService.searchByHttpParamsFromA3i(
        (parcel as any).registeredParcelsData._embedded.registeredParcels[0].id
      );
    });
  }

  calculateDate(date) {
    return this.timeService.utcToTimeZone(date);
  }

  openSubscribedServicesModal(mode) {
    this.subscribeServicePopupVisible = true;
    this.subscribeServicePopupMode = mode;
  }

  registerToIrrelisService() {
    console.log(this.parcelId);
  }

  registerToA3iService() {
    this.parcel$.subscribe(parcel => {
      // const registeredParcelId = 'parcel.registeredParcelsData._embedded.registeredParcels[0].id';
      // console.log(registeredParcelId);
    });
  }

  registerToPrevilisService() {
    console.log(this.parcelId);
  }
}
