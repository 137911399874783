import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseEntities } from 'src/app/core';
import { RegisterParcel } from './register-parcel.model';

@Injectable({
  providedIn: 'root',
})
export class ParcelRecommendationService {
  constructor(private httpClient: HttpClient) {}

  computeParcelRecommendations(parcelIds: string[], recommendationId: string) {
    const registerParcelUrl =
      environment.api +
      '/fs-parcel/api/parcelRecommendations/computations/byParcelIdsRecommendationId';
    let params = new HttpParams().set('recommendationId', recommendationId);
    parcelIds.forEach(parcelId => {
      params = params.append('parcelIds', parcelId);
    });
    return this.httpClient.post<ResponseEntities<RegisterParcel>>(registerParcelUrl, {
      recommendationId,
      parcelIds,
    });
  }
}
