import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, flatMap, shareReplay } from 'rxjs/operators';
import { Parcel } from 'src/app/shared';
import { Kind } from './kind.model';
import { ResponseEntities, tapLogError } from 'src/app/core';
import { toResponseEntities } from 'src/app/core/hal';
import { EntityProviderService } from 'src/app/core/hal/entity-provider.service';

@Injectable({
  providedIn: 'root',
})
export class KindService {
  relativeUrl = '/fs-agro-datum/api/kinds';
  fsAgroDatumKindApi = environment.api + this.relativeUrl;
  getByHttpParams = this.entityProviderService.httpGetter(Kind, this.relativeUrl);

  constructor(
    private httpClient: HttpClient,
    private entityProviderService: EntityProviderService
  ) {}

  getKindById(id: string): Observable<Kind> {
    const kindIdUrl = this.fsAgroDatumKindApi + '/' + id;
    return this.httpClient.get<Observable<Kind>>(kindIdUrl).pipe(
      map(a => new Kind(a)),
      tapLogError('getKindById')
    );
  }

  getKindFromParcel(parcel$: Observable<Parcel>): Observable<Kind> {
    return parcel$.pipe(
      map(a => a.agroData.crop.kindRefId),
      flatMap(kindRefId => this.getKindById(kindRefId)),
      shareReplay(1)
    );
  }

  getAllKinds(projection: string): Observable<Kind[]> {
    const params = { projection, sort: 'label,asc' };
    return this.httpClient.get<ResponseEntities<Kind>>(this.fsAgroDatumKindApi, { params }).pipe(
      toResponseEntities(Kind),
      map(re => re.getAllEmbedded()),
      shareReplay(1)
    );
  }
}
