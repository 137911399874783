import { NgModule } from '@angular/core';
import { ReportsModule } from './reports';
import { IngestionModule } from './ingestion';
import { FarmerCardModule } from './farmer-card';
import { TechnicianCardModule } from './technician-card';
import { SearchModule } from './search';
import { ParcelCardModule } from './parcel-card';
import { FarmersListModule } from './farmers-list';
import { TechniciansListModule } from './technicians-list';
import { CampaignsListModule } from './campaigns-list';
import { CooperativesListModule } from './cooperatives-list';
import { ParcelsListModule } from './parcels-list';
import { TestUiComponentsModule } from './test-ui-components';
import { ArvalisModule } from './arvalis';
import { HomePageModule } from './home-page';
import { ErrorsDailyFertiwebModule } from './errors-daily-fertiweb';
import { StatisticsModule } from './statistics/statistics.module';
import { LogbookDailyModule } from './logbook-daily';
import { UsersListModule } from './users-list/users-list.module';
import { ProductionTrackingModule } from './production-tracking/production-tracking.module';
import { RecommendationValidationModule } from './recommendation-validation/recommendation-validation.module';

@NgModule({
  imports: [
    // Features
    ReportsModule,
    IngestionModule,
    FarmerCardModule,
    TechnicianCardModule,
    SearchModule,
    ParcelCardModule,
    FarmersListModule,
    TechniciansListModule,
    CampaignsListModule,
    CooperativesListModule,
    ParcelsListModule,
    ArvalisModule,
    HomePageModule,
    ErrorsDailyFertiwebModule,
    StatisticsModule,
    LogbookDailyModule,
    UsersListModule,
    ProductionTrackingModule,
    // tmp
    TestUiComponentsModule,
    RecommendationValidationModule,
  ],
  declarations: [],
})
export class FeatureModule {}
