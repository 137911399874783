import { Component } from '@angular/core';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { MainItem } from './menu/main-item';
import { Item } from './menu/item';
import { MatDialog } from '@angular/material';
import { AboutComponent } from 'src/app/core/layout/about/about.component';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent {
  icons = faIcons;
  items: MainItem[];
  constructor(private router: Router, private dialog: MatDialog) {
    this.initMenuData();
  }

  private initMenuData() {
    this.items = [
      new MainItem('Accueil', this.icons.faHome, '/homepage', "Page d'accueil"),
      new MainItem('NAVIGATION', this.icons.faSitemap, null, 'Navigation', [
        new Item('Parcelles', null, '/parcels-list', 'Parcelles'),
        new Item('Exploitants', null, '/farmers-list', 'Exploitants'),
        new Item('Techniciens', null, '/technicians-list', 'Techniciens'),
      ]),
      new MainItem('Statistiques', this.icons.faChartPie, '/total-ndose', 'Statistiques'),
      new MainItem('SUIVI DE PRODUCTION', this.icons.faGamepad, null, 'Suivi de production', [
        new Item('Suivi clients', null, '/pdf-tracking', 'Suivi clients'),
        new Item('Suivi parcelles', null, '/recommendation-validation', 'Suivi parcelles'),
      ]),
      new MainItem('ÉCHANGES INSTITUTS', this.icons.faExchangeAlt, null, 'Échanges instituts', [
        new Item('Arvalis', null, '/arvalis', 'Arvalis'),
      ]),
      new MainItem('Ingestions', this.icons.faPollH, '/ingestion', 'Ingestions'),
      new MainItem('JOURNAUX', this.icons.faClipboardList, null, 'Journaux', [
        new Item('Erreurs fertiweb', null, '/errors-daily-fertiweb', 'Erreurs fertiweb'),
        new Item("Journal d'évènements", null, '/logbook-daily', "Journal d'évènements"),
      ]),
      new MainItem('ADMINISTRATION', this.icons.faUsersCog, null, 'Administration', [
        new Item('Campagnes', null, '/campaigns-list', 'Campagnes'),
        new Item('Coopératives', null, '/cooperatives-list', 'Coopératives'),
        new Item('Coordinateurs', null, '/coordinators-list', 'Coordinateurs'),
        new Item('Ingénieurs régionaux', null, '/regional-engineers-list', 'Ingénieurs régionaux'),
      ]),
    ];
  }

  openAboutModal() {
    this.dialog.open(AboutComponent, {
      width: '90%',
      height: '90%',
    });
  }
}
