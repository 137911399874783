import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { TechnicianService, Technician, Campaign, Cooperative } from 'src/app/shared';
import { tap } from 'rxjs/operators';
import { mapErrorToMsg, DxDatasourceCreatorService, ResponseEntities } from 'src/app/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-technicians-list',
  templateUrl: './technicians-list.component.html',
  styleUrls: ['./technicians-list.component.scss'],
})
export class TechniciansListComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  icons = faIcons;
  popupVisible = false;
  dataSource: DataSource;
  totalNbTechnicians: number;
  campaignFilter: string;
  coopFilter: string;

  constructor(
    private technicianService: TechnicianService,
    private datasourceCreator: DxDatasourceCreatorService
  ) {
    this.calculateCooperative.bind(this);
    this.calculateCodeInCoop.bind(this);
  }

  ngOnInit() {
    this.initDataSource();
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: searchParams =>
        this.loadTechnicianList(searchParams.httpParams, this.campaignFilter, this.coopFilter).pipe(
          tap(response => (this.totalNbTechnicians = response.page.totalElements)),
          mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !')
        ),
      getLoadParams: () => ({ httpParams: new HttpParams().set('projection', 'list') }),
    });
  }

  loadTechnicianList(
    httpParams: HttpParams,
    campaign: string,
    cooperative: string
  ): Observable<ResponseEntities<Technician>> {
    let params = httpParams;
    if (campaign) {
      params = params.set('registeredTechnicians.campaign', campaign);
    }
    if (cooperative) {
      params = params.set('registeredTechnicians.registeredCooperative.cooperative', cooperative);
    }
    return this.technicianService.getByHttpParams(params);
  }

  calculateFullName(technicianRow: Technician) {
    return technicianRow.getFullName();
  }

  exportDataGrid() {
    this.dataGrid.instance.exportToExcel(false);
  }

  applyCampaignFilter(filter: Campaign) {
    this.campaignFilter = filter ? filter.id : null;
    this.dataGrid.instance.refresh();
  }

  applyCoopFilter(filter: Cooperative) {
    this.coopFilter = filter ? filter.id : null;
    this.dataGrid.instance.refresh();
  }

  getRegisteredTechnicianFromCampaign(techRow: Technician, campaign: string) {
    return techRow.registeredTechnicians && campaign
      ? techRow.registeredTechnicians.find(rf => rf.campaign.id === campaign)
      : null;
  }

  calculateCooperative = (techRow: Technician) => {
    if (this.campaignFilter) {
      const registeredTechnician = this.getRegisteredTechnicianFromCampaign(
        techRow,
        this.campaignFilter
      );
      return registeredTechnician
        ? registeredTechnician.registeredCooperative.cooperative.code
        : '';
    }
    return techRow.registeredTechnicians
      ? techRow.registeredTechnicians.map(rf => rf.registeredCooperative.cooperative.code).join(',')
      : '';
  };

  calculateCodeInCoop = (techRow: Technician) => {
    if (this.campaignFilter) {
      const registeredTechnician = this.getRegisteredTechnicianFromCampaign(
        techRow,
        this.campaignFilter
      );
      return registeredTechnician ? registeredTechnician.codeInCoop : '';
    }
    return techRow.registeredTechnicians
      ? techRow.registeredTechnicians.map(rf => rf.codeInCoop).join(',')
      : '';
  };
}
