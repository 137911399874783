import { Component, OnInit, ViewChild } from '@angular/core';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { DxDataGridComponent } from 'devextreme-angular';
import { LatestErrorsService, ParcelRecommendationService, TimeService } from 'src/app/shared';
import DataSource from 'devextreme/data/data_source';
import { DxDatasourceCreatorService } from 'src/app/core/dx';
import { Observable } from 'rxjs';
import { ResponseEntities } from 'src/app/core';
import { Recommendation } from 'src/app/shared/recommendation/recommendation.model';
import { RecommendationValidationService } from 'src/app/feature/recommendation-validation/recommendation-validation.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-errors-daily-fertiweb',
  templateUrl: './errors-daily-fertiweb.component.html',
  styleUrls: ['./errors-daily-fertiweb.component.scss'],
})
export class ErrorsDailyFertiwebComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  icons = faIcons;
  dataSource: DataSource;
  totalNbErrors: number;
  datasource$: Observable<ResponseEntities<any>>;
  totalNRecommendation: Recommendation;
  paginate = true;
  constructor(
    private findLatestErrorsService: LatestErrorsService,
    private datasourceCreator: DxDatasourceCreatorService,
    private parcelRecommendationService: ParcelRecommendationService,
    private timeService: TimeService,
    private recommendationValidationService: RecommendationValidationService
  ) {
    this.calculateDate = this.calculateDate.bind(this);
  }

  ngOnInit() {
    this.initDataSource();
    this.recommendationValidationService.getRecommendations().subscribe(recommendations => {
      this.totalNRecommendation = recommendations.filter(e => e.code.includes('TOTALN'))[0];
    });
  }

  exportDataGrid() {
    this.dataGrid.instance.exportToExcel(false);
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: searchParams => {
        this.datasource$ = this.loadData(searchParams);
        return this.datasource$;
      },
    });
  }

  loadData(searchParams) {
    return this.findLatestErrorsService
      .getLatestsErrorsOfEachParcelByHttpParams(searchParams.httpParams)
      .pipe(
        tap(response => {
          if (searchParams.httpParams.get('requireTotalCount') === 'true') {
            this.totalNbErrors = response.page.totalElements;
          }
        })
      );
  }

  // FIXME : renommer en compute
  refreshParcelErrors() {
    this.datasource$.subscribe(responseEntities => {
      const parcelIds: string[] = responseEntities._.map(
        responseEntity => responseEntity.parcel.id
      );

      const uniqueParcelIds = Array.from(new Set(parcelIds));

      // FIXME : ça ne recalcule que la page en cours
      this.parcelRecommendationService
        .computeParcelRecommendations(uniqueParcelIds, this.totalNRecommendation.id)
        .subscribe(() => this.initDataSource());
    });
  }

  calculateDate(parcelError) {
    return this.timeService.utcToTimeZone(
      parcelError.fwRemoteParcelRequest.fwRemoteRequest.sendDate
    );
  }
}
