import { LinkResource } from 'src/app/core';

export class SubscribedParcels extends LinkResource {
  id: string;
  irrelisParcelCode: string;
  parcelDataSynchronized: Boolean;
  parcelRefId: string;
  subscriptionDate: Date;

  constructor(subscribedParcelsData?: any) {
    super(subscribedParcelsData);
    if (subscribedParcelsData) {
      Object.assign(this, subscribedParcelsData);
    }
  }
}
