import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Recommendation } from 'src/app/shared/recommendation/recommendation.model';
import { RecommendationValidationService } from 'src/app/feature/recommendation-validation/recommendation-validation.service';

@Component({
  selector: 'app-recommendation-select',
  templateUrl: './recommendation-select.component.html',
})
export class RecommendationSelectComponent implements OnChanges, OnInit {
  recommendations: Recommendation[] = [];
  selectedRecommendation: Recommendation;

  @Input() value: Recommendation;
  @Output() valueChange = new EventEmitter<Recommendation>();
  @Input() selectFirst: boolean;

  constructor(private recommendationValidationService: RecommendationValidationService) {}

  ngOnInit(): void {
    this.recommendationValidationService.getRecommendations().subscribe(recommendations => {
      this.recommendations = recommendations;
      if (this.selectFirst && this.recommendations && this.recommendations.length > 0) {
        this.selectedRecommendation = this.recommendations[0];
        this.valueChange.emit(this.selectedRecommendation);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.selectedRecommendation = changes.value.currentValue;
    }
  }

  updateData() {
    this.valueChange.emit(this.selectedRecommendation);
  }
}
