import { LinkResource, Entity } from 'src/app/core/hal';
import { FwRemoteParcelRequest } from 'src/app/shared/fs-fertiweb/fw-remote-parcel-request/fw-remote-parcel-request.model';
import { RegisteredParcel } from 'src/app/shared/registered-parcel/registered-parcel.model';
import { Parcel } from 'src/app/shared/parcel/parcel.model';

export class ParcelError extends LinkResource implements Entity {
  id: string;
  message: string;
  validation?: boolean;
  entite: string;
  fwRemoteParcelRequest: FwRemoteParcelRequest;

  registeredParcel?: RegisteredParcel;
  parcel?: Parcel;

  constructor(data?: any) {
    super(data);
    if (data) {
      Object.assign(this, data);
      if (data.fwRemoteParcelRequest) {
        this.fwRemoteParcelRequest = new FwRemoteParcelRequest(data.fwRemoteParcelRequest);
      }
      if (data.registeredParcel) {
        this.registeredParcel = new RegisteredParcel(data.registeredParcel);
      }
      if (data.parcel) {
        this.parcel = new Parcel(data.parcel);
      }
    }
  }
  getRelativeApiUrl = () => '/fs-connector-fertiweb/api/parcelErrors';
}
