import { Entity, LinkResource } from 'src/app/core/hal';
import { Cooperative } from 'src/app/shared';

export class RegisteredFarmer extends LinkResource implements Entity {
  id: string;
  farmerRefId: string;
  parcels: EmbeddedParcel[];
  campaign: EmbeddedCampaign;
  technicianRefId: string;
  registeredCooperative: EmbeddedRegisteredCooperative;
  registeredTechnician: EmbeddedRegisteredTechnician;
  cooperative?: Cooperative;
  codeInCoop?: string;

  getRelativeApiUrl = () => '/fs-core/api/registeredFarmers';
  constructor(registeredFarmerData?: any) {
    super(registeredFarmerData);
    if (registeredFarmerData) {
      Object.assign(this, registeredFarmerData);
      this.registeredCooperative = new EmbeddedRegisteredCooperative(
        registeredFarmerData.registeredCooperative
      );
      this.registeredTechnician = new EmbeddedRegisteredTechnician(
        registeredFarmerData.registeredTechnician
      );
      if (registeredFarmerData.parcels) {
        this.parcels = registeredFarmerData.parcels.map(Parcel => new EmbeddedParcel(Parcel));
      }
      if (registeredFarmerData.campaign) {
        this.campaign = new EmbeddedCampaign(registeredFarmerData.campaign);
      }
    }
  }
}

export class EmbeddedParcel extends LinkResource {
  id: string;
  parcelRefId: string;
  constructor(parcelData?: any) {
    super(parcelData);
    if (parcelData) {
      Object.assign(this, parcelData);
    }
  }
}

export class EmbeddedCampaign extends LinkResource {
  id: string;
  code: string;
  label: string;
  year: number;
  active: boolean;
  constructor(campaignData?: any) {
    super(campaignData);
    if (campaignData) {
      Object.assign(this, campaignData);
    }
  }
}

class EmbeddedRegisteredCooperative {
  id: string;
  cooperativeRefId: string;
  cooperative?: Cooperative;

  constructor(registeredCooperativeData?: any) {
    if (registeredCooperativeData) {
      Object.assign(this, registeredCooperativeData);
    }
  }
}

export class EmbeddedRegisteredTechnician {
  id: string;
  technicianRefId: string;

  constructor(registeredTechnicianData?: any) {
    if (registeredTechnicianData) {
      Object.assign(this, registeredTechnicianData);
    }
  }
}
