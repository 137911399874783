import { Entity, LinkResource } from 'src/app/core/hal';
import { Cooperative } from 'src/app/shared';
import { EmbeddedCampaign } from 'src/app/shared/registered-farmer/registered-farmer.model';

export class RegisteredTechnician extends LinkResource implements Entity {
  id: string;
  codeInCoop: string;
  technicianRefId: string;
  campaign?: EmbeddedCampaign;
  registeredFarmers: EmbeddedRegisteredFarmer[] = [];
  registeredCooperative: EmbeddedRegisteredCooperative;
  cooperative?: Cooperative;

  getRelativeApiUrl = () => '/fs-core/api/registeredTechnicians';

  constructor(registeredTechnicianData?: any) {
    super(registeredTechnicianData);
    if (registeredTechnicianData) {
      Object.assign(this, registeredTechnicianData);
      this.registeredCooperative = new EmbeddedRegisteredCooperative(
        registeredTechnicianData.registeredCooperative
      );
      if (registeredTechnicianData.campaign) {
        this.campaign = new EmbeddedCampaign(registeredTechnicianData.campaign);
      }
      if (registeredTechnicianData.registeredFarmers) {
        this.registeredFarmers = registeredTechnicianData.registeredFarmers.map(
          registeredFarmer => new EmbeddedRegisteredFarmer(registeredFarmer)
        );
      }
    }
  }
}

export class EmbeddedRegisteredFarmer extends LinkResource {
  farmerRefId: string;
  parcelCount: number;
  parcels: EmbeddedParcel[] = [];
  constructor(registeredFarmersData?: any) {
    super(registeredFarmersData);
    if (registeredFarmersData) {
      Object.assign(this, registeredFarmersData);
      if (registeredFarmersData.parcels) {
        this.parcels = registeredFarmersData.parcels.map(parcel => new EmbeddedParcel(parcel));
      }
    }
  }
}

class EmbeddedParcel extends LinkResource {
  id: string;
  parcelRefId: string;
  constructor(parcelData?: any) {
    super(parcelData);
    if (parcelData) {
      Object.assign(this, parcelData);
    }
  }
}

export class EmbeddedRegisteredCooperative extends LinkResource {
  id: string;
  cooperativeRefId: string;
  cooperative?: Cooperative;
  constructor(registeredCooperativeData?: any) {
    super(registeredCooperativeData);
    if (registeredCooperativeData) {
      Object.assign(this, registeredCooperativeData);
    }
  }
}
