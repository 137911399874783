import { Component } from '@angular/core';
import { RecommendationValidationFilter } from 'src/app/shared/recommendation/recommendation-validation-filter.model';

@Component({
  selector: 'app-recommendation-validation',
  templateUrl: './recommendation-validation.component.html',
  styleUrls: ['./recommendation-validation.component.scss'],
})
export class RecommendationValidationComponent {
  filter = new RecommendationValidationFilter();
}
