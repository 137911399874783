import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { shareReplay } from 'rxjs/internal/operators';
import { tapLogError } from 'src/app/core';
import { FileDownloaderService } from 'src/app/shared/files/file-downloader.service';

@Injectable()
export class RecommendationFileService {
  fsParcelRecommendationFileApi = environment.api + '/fs-parcel/api/recommendationFiles';

  constructor(private httpClient: HttpClient, private fileDownloader: FileDownloaderService) {}

  recommendationExistsForParcel(parcelId: string, recommendationId: string): Observable<boolean> {
    const url = this.fsParcelRecommendationFileApi + '/exist';
    const params = new HttpParams()
      .set('parcelId', parcelId)
      .set('recommendationId', recommendationId);
    return this.httpClient.post<boolean>(url, params).pipe(
      tapLogError('recommendationExistsForParcel'),
      shareReplay(1)
    );
  }

  downloadParcelReport(parcelId: string, recommendationId: string) {
    const url = this.fsParcelRecommendationFileApi + '/reports';
    const params = new HttpParams()
      .set('parcelId', parcelId)
      .set('recommendationId', recommendationId);
    this.fileDownloader.downloadFileFromGet('TotalN.pdf', url, params);
  }
}
