import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, flatMap, shareReplay } from 'rxjs/internal/operators';
import { ResponseEntities, toResponseEntities, tapLogError } from 'src/app/core';
import { Farmer } from './farmer.model';
import { RegisteredTechnician } from 'src/app/shared/registered-technician/registered-technician.model';
import { EntityProviderService } from 'src/app/core/hal/entity-provider.service';

@Injectable()
export class FarmerService {
  fsUserFarmerApi = environment.api + '/fs-core/api/farmers';
  getByHttpParams = this.entityProviderService.httpGetter(Farmer);

  constructor(
    private httpClient: HttpClient,
    private entityProviderService: EntityProviderService
  ) {}

  getFarmerById(id: string, projection: string): Observable<Farmer> {
    const farmerIdUrl = environment.api + '/fs-core/api/farmers/' + id;
    const params = { projection };
    return this.httpClient.get<Observable<Farmer>>(farmerIdUrl, { params }).pipe(
      map(a => new Farmer(a)),
      tapLogError('getFarmerById')
    );
  }

  getFarmersByIds(params: HttpParams): Observable<ResponseEntities<Farmer>> {
    return this.httpClient.get<ResponseEntities<Farmer>>(this.fsUserFarmerApi, { params }).pipe(
      toResponseEntities(Farmer),
      shareReplay(1)
    );
  }

  getFarmerByIdsWithEmbedded(
    ids: string[],
    projection: string,
    params: HttpParams
  ): Observable<ResponseEntities<Farmer>> {
    ids.forEach(id => (params = params.append('id', id)));
    return this.httpClient
      .get<ResponseEntities<Farmer>>(this.fsUserFarmerApi, {
        params: params.set('projection', projection),
      })
      .pipe(
        map(a => new ResponseEntities<Farmer>(Farmer, a)),
        tapLogError('getFarmerByIds'),
        shareReplay(1)
      );
  }

  getFarmersFromRegisteredTechnicians(
    registeredTechnicians$: Observable<ResponseEntities<RegisteredTechnician>>,
    projection: string,
    params: HttpParams
  ): Observable<ResponseEntities<Farmer>> {
    return registeredTechnicians$.pipe(
      map(registeredTechnicians =>
        registeredTechnicians
          .getAllEmbedded()
          .map(registeredTechnician => registeredTechnician.registeredFarmers)
          .reduce((registeredFarmers, curr) => registeredFarmers.concat(curr), [])
      ),
      map(registeredFarmers =>
        registeredFarmers
          .map(registeredFarmer => registeredFarmer.farmerRefId)
          .reduce((ids, curr) => ids.concat(curr), [])
      ),
      flatMap(ids => this.getFarmerByIdsWithEmbedded(ids, projection, params)),
      shareReplay(1)
    );
  }

  searchByHttpParams(params: HttpParams): Observable<ResponseEntities<Farmer>> {
    const url =
      environment.api +
      '/fs-core/api/farmers/search/' +
      'findByCodeIgnoreCaseContainingOrFirstNameIgnoreCaseContainingOrLastNameIgnoreCaseContainingOrCorporateNameIgnoreCaseContaining';

    return this.httpClient.get<ResponseEntities<Farmer>>(url, { params }).pipe(
      toResponseEntities(Farmer),
      shareReplay(1)
    );
  }

  // return ResponseEntities because we need totalCount
  search(searchParams: {
    httpParams: HttpParams;
    query: string;
  }): Observable<ResponseEntities<Farmer>> {
    return this.searchByHttpParams(searchParams.httpParams.set('value', searchParams.query));
  }
}
