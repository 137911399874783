import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MyAuthGuard } from './security/my-auth-guard';
import { SecuredComponent } from './security/secured.component';
import {
  IngestionComponent,
  ReportsComponent,
  TechnicianCardComponent,
  FarmerCardComponent,
  SearchComponent,
  ParcelCardComponent,
  FarmersListComponent,
  TechniciansListComponent,
  CampaignsListComponent,
  CooperativesListComponent,
  CoordinatorsListComponent,
  ParcelsListComponent,
  ArvalisComponent,
  HomePageComponent,
  ErrorsDailyFertiwebComponent,
  TotalNdoseComponent,
  LogbookDailyComponent,
} from 'src/app/feature';
import { RouteBuilder } from './security/RouteBuilder';
import { TestUiComponentsComponent } from './feature/test-ui-components';
import { RegionalEngineersListComponent } from './feature/users-list/regional-engineers-list/regional-engineers-list.component';
import { ProductionTrackingComponent } from './feature/production-tracking/production-tracking.component';
import { RecommendationValidationComponent } from './feature/recommendation-validation/recommendation-validation.component';

const routes: Routes = RouteBuilder.guard([
  { path: 'ingestion', component: IngestionComponent },
  { path: '', component: HomePageComponent },
  { path: 'homepage', component: HomePageComponent },
  { path: 'ingestion/:id/reports', component: ReportsComponent },
  { path: 'technician-card/:id', component: TechnicianCardComponent },
  { path: 'farmer-card/:id', component: FarmerCardComponent },
  { path: 'search', component: SearchComponent },
  { path: 'search/:query', component: SearchComponent },
  { path: 'parcel-card/:id', component: ParcelCardComponent },
  { path: 'farmers-list', component: FarmersListComponent },
  { path: 'technicians-list', component: TechniciansListComponent },
  { path: 'campaigns-list', component: CampaignsListComponent },
  { path: 'parcels-list', component: ParcelsListComponent },
  { path: 'secured', component: SecuredComponent, canActivate: [MyAuthGuard] },
  { path: 'cooperatives-list', component: CooperativesListComponent },
  { path: 'coordinators-list', component: CoordinatorsListComponent },
  { path: 'regional-engineers-list', component: RegionalEngineersListComponent },
  { path: 'arvalis', component: ArvalisComponent },
  { path: 'total-ndose', component: TotalNdoseComponent },
  { path: '__test-components__', component: TestUiComponentsComponent },
  { path: 'errors-daily-fertiweb', component: ErrorsDailyFertiwebComponent },
  { path: 'logbook-daily', component: LogbookDailyComponent },
  { path: 'pdf-tracking', component: ProductionTrackingComponent },
  { path: 'recommendation-validation', component: RecommendationValidationComponent },
]);

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: environment.debugRoutes })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
