import { Entity, LinkResource } from 'src/app/core/hal';

export class Recommendation extends LinkResource implements Entity {
  id: string;
  name: string;
  code: string;

  getRelativeApiUrl = () => '/fs-core/api/recommendations';

  constructor(data?: any) {
    super(data);
    if (data) {
      Object.assign(this, data);
    }
  }
}
