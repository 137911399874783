import { NgModule } from '@angular/core';
import { ErrorsDailyFertiwebComponent } from './errors-daily-fertiweb.component';
import { SharedModule } from 'src/app/shared';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ErrorsDailyFertiwebComponent],
  imports: [SharedModule, MatCheckboxModule, FormsModule],
  exports: [ErrorsDailyFertiwebComponent],
  providers: [],
})
export class ErrorsDailyFertiwebModule {}
