import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseEntities } from 'src/app/core';
import { RegisteredFarmer } from './registered-farmer.model';
import { EntityProviderService } from 'src/app/core/hal/entity-provider.service';

@Injectable()
export class RegisteredFarmerService {
  getByHttpParams = this.entityProviderService.httpGetter(RegisteredFarmer);

  constructor(
    private httpClient: HttpClient,
    private entityProviderService: EntityProviderService
  ) {}

  getRegisteredFarmersByFarmerId(farmerId: string): Observable<ResponseEntities<RegisteredFarmer>> {
    const param = new HttpParams().set('farmer.id', farmerId).set('projection', 'RegisteredFarmer');
    return this.getByHttpParams(param);
  }
}
