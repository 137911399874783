import { FileDownloaderService } from 'src/app/shared/files/file-downloader.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { HttpParams } from '@angular/common/http';
import { Entity, HttpMappingService, mapErrorToMsg, ResponseEntities } from 'src/app/core';
import {
  Campaign,
  CampaignService,
  Cooperative,
  CustomerDataFile,
  CustomerDataFileService,
} from 'src/app/shared';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';
import { DxDatasourceCreatorService } from 'src/app/core/dx';
import { EntityProviderService } from 'src/app/core/hal/entity-provider.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { TimeService } from 'src/app/shared';

@Component({
  selector: 'app-ingestion',
  templateUrl: './ingestion.component.html',
  styleUrls: ['./ingestion.component.scss'],
})
export class IngestionComponent implements OnInit {
  @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent;

  icons = faIcons;
  dataSource: DataSource;
  campaign: Campaign;
  cooperative: Cooperative;
  uploadPopupOpen = false;

  cooperativeMapping = {
    pathToDataInOutput: 'cooperative',
    pathToIdInInput: 'cooperativeRefId',
    projection: 'dropdown',
    targetType: Cooperative,
  };

  campaignMapping = {
    pathToDataInOutput: 'campaign',
    pathToIdInInput: 'campaignRefId',
    targetType: Campaign,
  };

  constructor(
    private campaignService: CampaignService,
    private customerDataFileService: CustomerDataFileService,
    private datasourceCreator: DxDatasourceCreatorService,
    private entityProviderService: EntityProviderService,
    private router: Router,
    private fileDownloaderService: FileDownloaderService,
    private timeService: TimeService,
    private httpMappingService: HttpMappingService
  ) {
    this.reload = this.reload.bind(this);
    this.calculateDate = this.calculateDate.bind(this);
  }

  ngOnInit() {
    this.initDataSource();
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: searchParams =>
        this.loadIngestionData(searchParams).pipe(
          this.httpMappingService.mapEntities<CustomerDataFile, Entity>(
            this.cooperativeMapping,
            this.campaignMapping
          ),
          mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !')
        ),
      getLoadParams: () => ({ httpParams: new HttpParams().set('projection', 'ingestion-list') }),
    });
  }

  loadIngestionData(searchParams): Observable<ResponseEntities<CustomerDataFile>> {
    return this.customerDataFileService.getDataFilesFromCampaignIdOrCooperativeId(
      searchParams.httpParams,
      this.campaign ? this.campaign.id : '',
      this.cooperative ? this.cooperative.id : ''
    );
  }

  reload() {
    this.datagrid.instance.refresh();
  }

  downloadZip(data) {
    // this.fileDownloaderService.downloadFileUrl(data.urlZip, 'application/zip', data.fileName);
    this.fileDownloaderService.downloadFileFromGet(data.fileName, data.urlZip).subscribe(() => {});
  }

  goToTestReports(data) {
    this.router.navigate(['/ingestion', data.id, 'reports']);
  }

  testFile(dataFile) {
    this.customerDataFileService
      .testDataFile(dataFile.id)
      .pipe(finalize(() => this.reload()))
      .subscribe();
  }

  importFile(dataFile) {
    this.customerDataFileService
      .importDataFile(dataFile.id)
      .pipe(finalize(() => this.reload()))
      .subscribe();
  }

  isStatus(cell, status: string) {
    return cell.data.status === status;
  }

  calculateDate(dataFile) {
    return this.timeService.utcToTimeZone(dataFile.lastActivityDate);
  }
}
