import { Injectable } from '@angular/core';

import { ParcelRecommendation } from 'src/app/shared/recommendation/parcel-recommendation.model';
import { RecommendationValidationFilter } from 'src/app/shared/recommendation/recommendation-validation-filter.model';
import { Observable, of } from 'rxjs';
import { Recommendation } from 'src/app/shared/recommendation/recommendation.model';
import { EntityProviderService, ResponseEntities } from 'src/app/core/hal';
import { map, shareReplay } from 'rxjs/operators';
import { Actions } from 'src/app/shared/recommendation/statuses.model';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpMappingService } from 'src/app/core/rxjs-operators/http-mapping';
import { Campaign, Cooperative, RegisteredCooperative } from 'src/app/shared';
import { RecommendationValidationStatusType } from 'src/app/shared/recommendation/recommendation-validation-status-type.model';

@Injectable({
  providedIn: 'root',
})
export class RecommendationValidationService {
  api = environment.api + new ParcelRecommendation().getRelativeApiUrl();

  recommendationHttpGet = this.entityProviderService.httpGetter(Recommendation);

  parcelRecommendationSearchResultHttpGet = this.entityProviderService.httpGetter(
    ParcelRecommendation,
    '/fs-parcel/api/parcelRecommendations/searchByFilter'
  );
  registeredCooperativeGetter = this.entityProviderService.httpGetter(RegisteredCooperative);

  private static getValidationStatus(isValid: boolean): RecommendationValidationStatusType {
    return isValid
      ? RecommendationValidationStatusType.NOMINAL
      : RecommendationValidationStatusType.INVALID;
  }

  constructor(
    private entityProviderService: EntityProviderService,
    private toasterService: ToastrService,
    private httpClient: HttpClient,
    private httpMappingService: HttpMappingService
  ) {}

  getData(
    filter: RecommendationValidationFilter,
    searchParams: HttpParams
  ): Observable<ResponseEntities<ParcelRecommendation>> {
    let ret;

    if (filter.recommendation) {
      let params = searchParams
        .set('recommendationId', filter.recommendation.id)
        .set('active', 'true');
      params = filter.addToParams(params);

      ret = this.parcelRecommendationSearchResultHttpGet(params).pipe(
        shareReplay(1),
        this.httpMappingService.mapEntities({
          pathToIdInInput: 'parcel.registeredCooperativeRefId',
          targetType: RegisteredCooperative,
          pathToDataInOutput: 'registeredCooperative',
          projection: 'cooperative',
        })
      );
    } else {
      ret = of(new ResponseEntities<ParcelRecommendation>(ParcelRecommendation));
    }

    return ret;
  }

  getRecommendations(): Observable<Recommendation[]> {
    return this.recommendationHttpGet().pipe(map(respEntities => respEntities.getAllEmbedded()));
  }

  getRegisteredCooperativebyCampaignAndCooperative(
    campaign: Campaign,
    cooperative: Cooperative
  ): Observable<ResponseEntities<RegisteredCooperative>> {
    let param;
    if (campaign && cooperative) {
      param = new HttpParams().set('campaign', campaign.id).set('cooperative', cooperative.id);
    } else if (campaign) {
      param = new HttpParams().set('campaign', campaign.id);
    } else if (cooperative) {
      param = new HttpParams().set('cooperative', cooperative.id);
    }
    return param
      ? this.registeredCooperativeGetter(param)
      : of(new ResponseEntities(RegisteredCooperative));
  }

  triggerAction(
    parcelRecommendation: ParcelRecommendation,
    dataField: string,
    action: Actions,
    message: string
  ): Observable<Object> {
    switch (dataField) {
      case 'computation':
        if (action === 'RUN' || action === 'RERUN') {
          return this.compute(parcelRecommendation);
        }
        break;

      case 'production':
        if (action === 'RUN' || action === 'RERUN') {
          return this.runPdfProduction(parcelRecommendation);
        }
        break;
      case 'expertValidation':
      case 'customerSupportValidation':
      case 'instituteValidation':
        if (action === 'VALIDATE' || action === 'INVALIDATE') {
          return this.sendValidation(
            parcelRecommendation,
            action === 'VALIDATE',
            dataField,
            message
          );
        }
        break;
      case 'delivery':
        if (action === 'RUN') {
          return this.deliver(parcelRecommendation, '');
        }
        break;

      default:
        this.toasterService.info(
          `TODO !  Action "${dataField}/${action}" envoyée avec le message "${message}" pour la parcelRecommendation "${
            parcelRecommendation.id
          }"`
        );
    }
  }

  private compute(parcelRecommendation: ParcelRecommendation): Observable<Object> {
    return this.httpClient.post(`${this.api}/${parcelRecommendation.id}/computation`, null);
  }

  private runPdfProduction(parcelRecommendation: ParcelRecommendation): Observable<Object> {
    return this.httpClient.post(`${this.api}/${parcelRecommendation.id}/production`, null);
  }
  private sendValidation(
    parcelRecommendation,
    isValid: boolean,
    dataField: string,
    message: string
  ): Observable<Object> {
    return this.httpClient.post(
      `${this.api}/${parcelRecommendation.id}/${dataField}`,
      {},
      {
        params: new HttpParams()
          .set('status', RecommendationValidationService.getValidationStatus(isValid))
          .set('message', message),
      }
    );
  }

  private deliver(parcelRecommendation: ParcelRecommendation, message: string): Observable<Object> {
    return this.httpClient.post(`${this.api}/${parcelRecommendation.id}/delivery`, null, {
      params: new HttpParams()
        .set('status', RecommendationValidationStatusType.NOMINAL)
        .set('message', message),
    });
  }
}
