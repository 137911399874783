import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Crop } from 'src/app/shared/crop/crop.model';
import { KindService } from 'src/app/shared/kind/kind.service';

@Component({
  selector: 'app-kind-select',
  templateUrl: './kind-select.component.html',
  styleUrls: ['./kind-select.component.scss'],
})
export class KindSelectComponent implements OnInit {
  @Input() campaign: string;
  @Input() value: Crop;
  @Output() valueChange = new EventEmitter<Crop>();

  kinds$: Observable<Crop[]>;

  constructor(private kindService: KindService) {}

  ngOnInit() {
    this.kinds$ = this.kindService.getAllKinds('dropdown');
  }
}
