import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  Crop,
  Campaign,
  NDoseComputationMethod,
  ParcelService,
  ParcelRecommendationService,
} from 'src/app/shared';
import { ToastrService } from 'ngx-toastr';
import { RecommendationValidationService } from 'src/app/feature/recommendation-validation/recommendation-validation.service';
import { Recommendation } from 'src/app/shared/recommendation/recommendation.model';
@Component({
  selector: 'app-total-ndose-compute',
  templateUrl: './total-ndose-compute-modal.component.html',
  styleUrls: ['./total-ndose-compute-modal.component.scss'],
})
export class TotalNdoseComputeModalComponent implements OnInit {
  @Input() isOpen: boolean;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() doseComputed = new EventEmitter<void>();

  onDisposing = this.closeModal.bind(this);
  onCompute = this.computeTotalDose.bind(this);

  campaign: Campaign;
  crop: Crop;
  computationMethod: NDoseComputationMethod;
  totalNRecommendation: Recommendation;
  constructor(
    private parcelService: ParcelService,
    private toastrService: ToastrService,
    private parcelRecommendationService: ParcelRecommendationService,
    private recommendationValidationService: RecommendationValidationService
  ) {}

  ngOnInit() {
    this.recommendationValidationService.getRecommendations().subscribe(recommendations => {
      this.totalNRecommendation = recommendations.filter(e => e.code.includes('TOTALN'))[0];
    });
  }

  closeModal() {
    // Clear values to have empty fields the next time we open the modal
    this.campaign = null;
    this.crop = null;
    this.computationMethod = null;

    this.isOpenChange.emit(false);
  }

  computeTotalDose() {
    this.parcelService
      .getParcelsbyCampaignCropComputationMethod(
        this.campaign.id,
        this.crop.id,
        this.computationMethod.id
      )
      .subscribe(parcelIds => {
        return this.parcelRecommendationService
          .computeParcelRecommendations(parcelIds, this.totalNRecommendation.id)
          .subscribe(
            () => {
              this.doseComputed.emit();
              this.closeModal();
            },
            error => {
              this.toastrService.error('Une erreur est survenue lors du calcul de la dose totale.');
            },
            () => {
              this.toastrService.success('La dose totale a été recalculée');
            }
          );
      });
  }

  isFormValid() {
    return this.campaign && this.crop && this.computationMethod;
  }
}
