import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { ProductionStepStatus } from 'src/app/shared/recommendation/production-step-status.model';
import { RecommendationValidationStatusType } from 'src/app/shared/recommendation/recommendation-validation-status-type.model';
import { Actions } from 'src/app/shared/recommendation/statuses.model';
import { ParcelRecommendation } from 'src/app/shared/recommendation/parcel-recommendation.model';
import { RecommendationValidationService } from 'src/app/feature/recommendation-validation/recommendation-validation.service';
import { DxMenuComponent } from 'devextreme-angular';

@Component({
  selector: 'app-recommendation-validation-status-menu',
  templateUrl: './recommendation-validation-status-menu.component.html',
  styleUrls: ['./recommendation-validation-status-menu.component.scss'],
})
export class RecommendationValidationStatusMenuComponent implements OnChanges {
  @Input() status: ProductionStepStatus;
  @Input() dataField: string;
  @Input() parcelRecommendation: ParcelRecommendation;
  @ViewChild('menu') menu: DxMenuComponent;
  @Output() needRefresh = new EventEmitter<string>();
  icons = faIcons;
  menuItems = [];
  workflowActions;

  constructor(private recommendationValidationService: RecommendationValidationService) {
    this.initWorkflowAction();
  }

  private initWorkflowAction() {
    // COMPUTATION
    const reRunComputation = {
      text: 'Relancer le calcul',
      icon: this.icons.faRedo,
      action: Actions.RERUN,
    };
    const computation = {
      TODO: [{ text: 'Lancer le calcul', icon: this.icons.faCheck, action: Actions.RUN }],
      NOMINAL: [reRunComputation],
      TECH_ERROR: [reRunComputation],
      INVALID: [reRunComputation],
      PENDING: [],
    };
    // PDF
    const reRunPdf = {
      text: 'Relancer la production du PDF',
      icon: this.icons.faRedo,
      action: Actions.RERUN,
    };
    const production = {
      TODO: [{ text: 'Produire le PDF', icon: this.icons.faCheck, action: Actions.RUN }],
      NOMINAL: [reRunPdf],
      TECH_ERROR: [reRunPdf],
      INVALID: [reRunPdf],
      PENDING: [],
    };

    // validation
    const validate = { text: 'Valider', icon: this.icons.faCheck, action: Actions.VALIDATE };
    const unvalidate = {
      text: 'Invalider',
      icon: this.icons.faMinusCircle,
      action: Actions.INVALIDATE,
      template: 'itemInvalidate',
      closeMenuOnClick: false,
    };
    const validation = {
      TODO: [validate, unvalidate],
      NOMINAL: [unvalidate],
      TECH_ERROR: [validate],
      INVALID: [validate],
      PENDING: [],
    };
    const deliver = {
      TODO: [{ text: 'Livrer', icon: this.icons.faGift, action: Actions.RUN }],
      NOMINAL: [],
      TECH_ERROR: [],
      INVALID: [],
      PENDING: [],
    };

    this.workflowActions = {
      computation: { actions: computation, requirements: [] },
      production: { actions: production, requirements: ['computation'] },
      expertValidation: { actions: validation, requirements: ['production'] },
      customerSupportValidation: { actions: validation, requirements: ['expertValidation'] },
      instituteValidation: { actions: validation, requirements: ['expertValidation'] },
      delivery: {
        actions: deliver,
        requirements: ['customerSupportValidation', 'instituteValidation'],
      },
    };
  }

  triggerInvalidateAction(item, text) {
    this.parcelRecommendation.statuses[item.dataField].status =
      RecommendationValidationStatusType.PENDING;
    this.recommendationValidationService
      .triggerAction(this.parcelRecommendation, item.dataField, item.action, text)
      .subscribe(() => this.needRefresh.emit(), () => this.needRefresh.emit());
    this.menu.instance.unselectItem(this.menu.instance.option('items')[0]);
  }

  itemClick(event) {
    if (event.itemData && event.itemData.dataField) {
      this.parcelRecommendation.statuses[event.itemData.dataField].status =
        RecommendationValidationStatusType.PENDING;
      if (event.itemData.action !== Actions.INVALIDATE) {
        this.recommendationValidationService
          .triggerAction(
            this.parcelRecommendation,
            event.itemData.dataField,
            event.itemData.action,
            ''
          )
          .subscribe(() => this.needRefresh.emit(), () => this.needRefresh.emit());
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateMenuItems();
  }

  private updateMenuItems() {
    if (this.status && this.dataField) {
      const menuItems = {
        icon: this.getIcon(),
        template: 'status',
        items: [],
      };

      if (!this.isPending()) {
        if (this.status.message) {
          menuItems.items.push({
            text: this.status.message,
            icon: this.icons.faInfoCircle,
            template: 'title',
          });
        }
        menuItems.items.push(...this.getItems());
      }

      this.menuItems = [menuItems];
    } else {
      this.menuItems = [];
    }
  }

  private getIcon() {
    let icon: faIcons.IconDefinition = null;
    switch (this.status.status) {
      case RecommendationValidationStatusType.INVALID:
        icon = this.icons.faExclamationTriangle;
        break;
      case RecommendationValidationStatusType.NOMINAL:
        icon =
          this.dataField === 'statuses.delivery' ? this.icons.faGift : this.icons.faCheckCircle;
        break;
      case RecommendationValidationStatusType.PENDING:
        icon = this.icons.faSpinner;
        break;
      case RecommendationValidationStatusType.TECH_ERROR:
        icon = this.icons.faExclamationCircle;
        break;
      case RecommendationValidationStatusType.TODO:
        icon = this.icons.faQuestionCircle;
        break;
    }
    return icon;
  }

  isPending() {
    return this.status.status === RecommendationValidationStatusType.PENDING;
  }

  private getItems(): any[] {
    const dataFieldName = this.getDataFieldName();

    const items: any[] = this.workflowActions[dataFieldName].actions[this.status.status.toString()];
    if (items) {
      items.forEach(item => (item.template = item.template || 'item'));
      items.forEach(item => (item.dataField = dataFieldName));
    }

    return items;
  }

  getDataFieldName() {
    return this.dataField ? this.dataField.substring('statuses.'.length) : '';
  }

  previousStatusIsDone(): boolean {
    const dataFieldName = this.getDataFieldName();

    let allPreviousStepsAreDone;

    if (dataFieldName) {
      const requirements: string[] = this.workflowActions[dataFieldName].requirements;
      allPreviousStepsAreDone = requirements.every(
        stepName =>
          this.parcelRecommendation.statuses[stepName].status ===
          RecommendationValidationStatusType.NOMINAL
      );
    } else {
      allPreviousStepsAreDone = false;
    }
    return allPreviousStepsAreDone;
  }
}
