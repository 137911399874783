import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { RecommendationValidationFilter } from 'src/app/shared/recommendation/recommendation-validation-filter.model';
import { RecommendationValidationService } from 'src/app/feature/recommendation-validation/recommendation-validation.service';
import { mapErrorToMsg } from 'src/app/core/rxjs-operators';
import { DxDatasourceCreatorService } from 'src/app/core/dx';
import DataSource from 'devextreme/data/data_source';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-recommendation-validation-datagrid',
  templateUrl: './recommendation-validation-datagrid.component.html',
  styleUrls: ['./recommendation-validation-datagrid.component.scss'],
})
export class RecommendationValidationDatagridComponent implements OnChanges, OnInit {
  @Input() filter: RecommendationValidationFilter;
  @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent;
  dataSource: DataSource;

  icons = faIcons;
  constructor(
    private recommendationValidationService: RecommendationValidationService,
    private datasourceCreator: DxDatasourceCreatorService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter && this.dataSource) {
      this.updateData();
    }
  }

  ngOnInit() {
    this.initDataSource();
    this.updateData();
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: param =>
        this.recommendationValidationService
          .getData(this.filter, param.httpParams)
          .pipe(mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !')),
    });
  }

  updateData() {
    if (this.datagrid && this.datagrid.instance) {
      this.datagrid.instance.refresh();
    }
  }
}
