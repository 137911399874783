import { ProductionStepStatus } from './production-step-status.model';

export class Statuses {
  computation: ProductionStepStatus;
  production: ProductionStepStatus;
  expertValidation: ProductionStepStatus;
  customerSupportValidation: ProductionStepStatus;
  instituteValidation: ProductionStepStatus;
  delivery: ProductionStepStatus;
}

export enum StatusesFields {
  COMPUTATION = 'computation',
  PRODUCTION = 'production',
  EXPERT_VALIDATION = 'expertValidation',
  CUSTOMER_SUPPORT_VALIDATION = 'customerSupportValidation',
  INSTITUTE_VALIDATION = 'instituteValidation',
  DELIVERY = 'delivery',
}

export enum Actions {
  RUN = 'RUN',
  RERUN = 'RERUN',
  VALIDATE = 'VALIDATE',
  INVALIDATE = 'INVALIDATE',
}
