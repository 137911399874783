import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, mergeMap, shareReplay } from 'rxjs/internal/operators';
import { ResponseEntities, toResponseEntities, tapLogError } from 'src/app/core';
import { Technician } from './technician.model';
import { RegisteredFarmer } from 'src/app/shared/registered-farmer/registered-farmer.model';
import { EntityProviderService } from 'src/app/core/hal/entity-provider.service';

@Injectable()
export class TechnicianService {
  fsUserTechnicianApi = environment.api + '/fs-core/api/technicians';
  getByHttpParams = this.entityProviderService.httpGetter(Technician);

  constructor(
    private httpClient: HttpClient,
    private entityProviderService: EntityProviderService
  ) {}

  getTechnicianById(id: string, projection: string): Observable<Technician> {
    const technicianIdUrl = this.fsUserTechnicianApi + `/${id}`;
    const params = { projection };
    return this.httpClient.get<Observable<Technician>>(technicianIdUrl, { params }).pipe(
      map(a => new Technician(a)),
      tapLogError('getTechnicianById')
    );
  }

  // return ResponseEntities because we need totalCount
  search(searchParams: {
    httpParams: HttpParams;
    query: string;
  }): Observable<ResponseEntities<Technician>> {
    const url =
      this.fsUserTechnicianApi +
      '/search/findByCodeIgnoreCaseContainingOrFirstNameIgnoreCaseContainingOrLastNameIgnoreCaseContaining';

    return this.httpClient
      .get<ResponseEntities<Technician>>(url, {
        params: searchParams.httpParams.set('value', searchParams.query),
      })
      .pipe(
        toResponseEntities(Technician),
        shareReplay(1)
      );
  }

  getTechniciansFromRegisteredFarmers(
    registeredFarmers$: Observable<ResponseEntities<RegisteredFarmer>>,
    projection: string
  ): Observable<ResponseEntities<Technician>> {
    return registeredFarmers$.pipe(
      map(registeredFarmers =>
        registeredFarmers
          .getAllEmbedded()
          .map(registeredFarmer => registeredFarmer.technicianRefId)
          .reduce((ids, curr) => ids.concat(curr), [])
      ),
      mergeMap(technicianRefIds =>
        this.getByHttpParams(this.getHttpParamFromIds(technicianRefIds, projection))
      ),
      shareReplay(1)
    );
  }

  getHttpParamFromIds(ids: string[], projection: string): HttpParams {
    return ids.reduce(
      (params, id) => params.append('id', id),
      new HttpParams().set('projection', projection)
    );
  }
}
