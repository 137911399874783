import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RecommendationValidationFilter } from 'src/app/shared/recommendation/recommendation-validation-filter.model';
import { RecommendationValidationService } from 'src/app/feature/recommendation-validation/recommendation-validation.service';

@Component({
  selector: 'app-recommendation-validation-filter',
  templateUrl: './recommendation-validation-filter.component.html',
  styleUrls: ['./recommendation-validation-filter.component.scss'],
})
export class RecommendationValidationFilterComponent {
  type = 'table';
  @Input() filter: RecommendationValidationFilter;
  @Output() filterChange = new EventEmitter<RecommendationValidationFilter>();

  constructor(private recommendationValidationService: RecommendationValidationService) {}

  onUpdate() {
    this.filter = new RecommendationValidationFilter(this.filter);
    this.filterChange.emit(this.filter);
  }

  onCampaignOrCooperativeUpdate() {
    this.recommendationValidationService
      .getRegisteredCooperativebyCampaignAndCooperative(
        this.filter.campaign,
        this.filter.cooperative
      )
      .subscribe(registeredCooperatives => {
        this.filter.registeredCooperativeRefIds = registeredCooperatives
          .getAllEmbedded()
          .map(regCoop => regCoop.id);

        if (
          (this.filter.campaign || this.filter.cooperative) &&
          this.filter.registeredCooperativeRefIds.length === 0
        ) {
          // HACK/astuce : le filtre avec cette campagne et coop ne correspond à aucune registeredCooperative
          // on définie une registeredCooperative qui n'existe pas pour que la recherch ne trouve aucun
          // résultat (sinon le filtre par registeredCooperative sera désactivé)
          this.filter.registeredCooperativeRefIds = ['empty_registered_cooperative_ref_ids'];
        }

        this.filter = new RecommendationValidationFilter(this.filter);
        this.filterChange.emit(this.filter);
      });
  }
}
