import { LinkResource, Entity } from 'src/app/core/hal';
import { FwRemoteRequest } from 'src/app/shared/fs-fertiweb/fw-remote-request/fw-remote-request.model';

export class FwRemoteParcelRequest extends LinkResource implements Entity {
  id: string;
  fwRemoteRequest: FwRemoteRequest;
  registeredParcelId: string;
  failed: boolean;

  constructor(data?: any) {
    super(data);
    if (data) {
      Object.assign(this, data);
      if (data.fwRemoteRequest) {
        this.fwRemoteRequest = new FwRemoteRequest(data.fwRemoteRequest);
      }
    }
  }
  getRelativeApiUrl = () => '/fs-connector-fertiweb/api/fwRemoteParcelRequests';
}
