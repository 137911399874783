import { RecommendationValidationStatusType } from './recommendation-validation-status-type.model';

export class ProductionStepStatus {
  message: string;
  status: RecommendationValidationStatusType;
  constructor(status: RecommendationValidationStatusType, message: string) {
    this.status = status;
    this.message = message;
  }
}
