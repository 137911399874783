import { NgModule } from '@angular/core';
import { CampaignService } from './campaign/campaign.service';
import { CooperativeService } from './cooperative/cooperative.service';
import { UserService } from './user/user.service';
import { CustomerDataFileService } from './customer-data-file/customer-data-file.service';
import { FarmerService } from './farmer/farmer.service';
import { TechnicianService } from './technician/technician.service';
import { RegisteredTechnicianService } from './registered-technician/registered-technician.service';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  MatButtonModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MatFormFieldModule,
  MatSelectModule,
  MatButtonToggleModule,
  MatInputModule,
  MatCardModule,
} from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import {
  DxChartModule,
  DxDataGridModule,
  DxFilterBuilderModule,
  DxSelectBoxModule,
  DxTabsModule,
  DxTooltipModule,
  DxPopupModule,
  DxFormModule,
  DxFileUploaderModule,
  DxTagBoxModule,
  DxLinearGaugeModule,
  DxPieChartModule,
  DxBulletModule,
} from 'devextreme-angular';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisteredFarmerService } from './registered-farmer/registered-farmer.service';
import { TestReportService } from './test-report/test-report.service';
import { IngestionReportService } from './ingestion-report/ingestion-report.service';
import { ParcelService } from './parcel/parcel.service';
import { CropService } from './crop/crop.service';
import { VarietyService } from './variety/variety.service';
import { RegisteredCooperativeService } from './registered-cooperative/registered-cooperative.service';
import { CatchCropService } from './catch-crop/catch-crop.service';
import { GrasslandTypeService } from './grassland-type/grassland-type.service';
import { GrasslandUsageService } from './grassland-usage/grassland-usage.service';
import { HistoricCropWasteUsageService } from './historic-crop-waste-usage/historic-crop-waste-usage.service';
import { InputModeService } from './input-mode/input-mode.service';
import { KindService } from './kind/kind.service';
import { NDoseComputationEngineService } from './n-dose-computation-engine/n-dose-computation-engine.service';
import { NDoseComputationMethodsService } from './n-dose-computation-method/n-dose-computation-method.service';
import { OrganicWasteService } from './organic-waste/organic-waste.service';
import { PeriodicInputService } from './periodic-input/periodic-input.service';
import { PreviousCropService } from './previous-crop/previous-crop.service';
import { PreviousCropWasteUsageService } from './previous-crop-waste-usage/previous-crop-waste-usage.service';
import { ReversedGrasslandAgeService } from './reversed-grassland-age/reversed-grassland-age.service';
import { SoilService } from './soil/soil.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ParcelRecommendationService } from './register-parcel/parcel-recommendation.service';
import { ArvalisService } from './arvalis/arvalis.service';
import { FileDownloaderService } from './files/file-downloader.service';
import { LatestErrorsService } from './latest-errors/latest-errors.service';
import { RegisteredParcelService } from './registered-parcel/registered-parcel.service';
import { LogbookService } from './logbook/logbook.service';
import { PipesModule } from './pipes/pipes.module';
import { MapComponent } from './parcel/map/map.component';
import { ComponentsModule } from './components/components.module';
import { PieChartService } from './pie-chart/pie-chart.service';
import { TimeTrackingModalComponent } from './parcel/map/time-tracking-modal/time-tracking-modal.component';
import { BiophyToggleComponent } from './parcel/map/biophy-toggle/biophy-toggle.component';
import { ProgressBarIndicatorComponent } from './progress-bar-indicator/progress-bar-indicator.component';
import { IrrigationChartComponent } from './parcel/map/time-tracking-modal/irrigation-chart/irrigation-chart.component';
import { RecommendationFileService } from './recommendation/recommendation-file.service';

/**
 * Contain exports commons to the whole application
 */
@NgModule({
  declarations: [
    MapComponent,
    TimeTrackingModalComponent,
    BiophyToggleComponent,
    ProgressBarIndicatorComponent,
    IrrigationChartComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    FontAwesomeModule,
    DxDataGridModule,
    MatButtonToggleModule,
    MatTooltipModule,
    DxPopupModule,
    DxChartModule,
    ComponentsModule,
    PipesModule,
    FlexLayoutModule,
    RouterModule,
  ],
  exports: [
    MapComponent,
    // Angular core
    HttpClientModule,
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,

    // Angular material
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatTooltipModule,
    MatDialogModule,
    MatGridListModule,
    MatTabsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonToggleModule,

    // Dev Extreme
    DxDataGridModule,
    DxTabsModule,
    DxSelectBoxModule,
    DxFilterBuilderModule,
    DxTooltipModule,
    DxPopupModule,
    DxFormModule,
    DxFileUploaderModule,
    DxTagBoxModule,
    DxLinearGaugeModule,
    DxChartModule,
    DxPieChartModule,
    DxBulletModule,

    // FontAwesome
    FontAwesomeModule,

    // App modules,
    ComponentsModule,
    PipesModule,

    FlexLayoutModule,
    ProgressBarIndicatorComponent,
  ],
  providers: [
    CampaignService,
    CooperativeService,
    UserService,
    CustomerDataFileService,
    FarmerService,
    TechnicianService,
    RegisteredFarmerService,
    RegisteredTechnicianService,
    TestReportService,
    IngestionReportService,
    ParcelService,
    CropService,
    VarietyService,
    RegisteredCooperativeService,
    CatchCropService,
    GrasslandTypeService,
    GrasslandUsageService,
    HistoricCropWasteUsageService,
    InputModeService,
    KindService,
    NDoseComputationEngineService,
    NDoseComputationMethodsService,
    OrganicWasteService,
    PeriodicInputService,
    PreviousCropService,
    PreviousCropWasteUsageService,
    ReversedGrasslandAgeService,
    SoilService,
    ParcelRecommendationService,
    ArvalisService,
    FileDownloaderService,
    LatestErrorsService,
    RegisteredParcelService,
    LogbookService,
    PieChartService,
    RecommendationFileService,
  ],
})
export class SharedModule {}
